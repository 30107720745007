import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

const Page = ({ children, title }) => {
  const [pushed, setPushed] = useState(false);

  const capitalize = s => {
    if (typeof s !== 'string') return '';
    return s.charAt(0).toUpperCase() + s.slice(1);
  };

  const splitLocation = () => {
    if (title && title !== '') {
      return `${capitalize(title)} - Scoopr`;
    }
    return 'Scoopr';
  };

  useEffect(() => {
    if (!pushed && title && title !== '') {
      document.title = splitLocation();
      setPushed(true);
    }
  }, [title]);
  return <>{children}</>;
};

Page.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
};

Page.defaultProps = {
  children: null,
  title: '',
};

export default Page;

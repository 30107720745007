import React, { Suspense, lazy } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import { LANG } from '../services/Config';
import Loader from '../components/Loader';
import ProtectedMyScooprRoute from '../services/Auth/ProtectedMyScooprRoute';

const MyScoopr = lazy(() => import('./MyScoopr/MyScoopr'));
const ErrorPage = lazy(() => import('./ErrorPage'));
const MyDebtMyScoopr = lazy(() => import('./MyScoopr/_pages/MyDebt'));
const EmailMyScoopr = lazy(() => import('./MyScoopr/_pages/Email'));
const LoginRedirectMyScoopr = lazy(() =>
  import('./MyScoopr/_pages/LoginRedirect'),
);
const ConsentsMyScoopr = lazy(() => import('./MyScoopr/_pages/Consents'));
const MyConsentsMyScoopr = lazy(() => import('./MyScoopr/_pages/MyConsents'));
const MyCreditScoreMyScoopr = lazy(() =>
  import('./MyScoopr/_pages/MyCreditScore'),
);
const SavingsPotentialMyScoopr = lazy(() =>
  import('./MyScoopr/_pages/SavingsPotential'),
);
const Login = lazy(() => import('./MyScoopr/_pages/Login'));
const Dashboard = lazy(() => import('./MyScoopr/_pages/Dashboard'));
const LiveOffers = lazy(() => import('./LiveOffers'));
const Home = lazy(() => import('./Home'));
const About = lazy(() => import('./About'));
const Terms = lazy(() => import('./Terms'));
const TermsRefinancing = lazy(() => import('./TermsRefinancing'));
const TermsMinScoopr = lazy(() => import('./TermsMinScoopr'));
const TermsMortgage = lazy(() => import('./TermsMortgage'));
const Cookies = lazy(() => import('./Cookies'));
const Privacy = lazy(() => import('./Privacy'));
const PrivacyStatement = lazy(() => import('./PrivacyStatement'));
const PartnerBanks = lazy(() => import('./PartnerBanks'));
const PartnerBankDetails = lazy(() => import('./PartnerBankDetails'));
const CustomerService = lazy(() => import('./CustomerService'));
const FaqArticle = lazy(() => import('./FaqArticle'));
const NoMatch = lazy(() => import('./NoMatch'));
const Refinancing = lazy(() => import('./Refinancing'));
const DebtRegister = lazy(() => import('./DebtRegister'));
const DebtInformation = lazy(() => import('./DebtInformation'));
const Loan = lazy(() => import('./Loan'));
const Insurance = lazy(() => import('./Insurance'));
const InsuranceDataPolicy = lazy(() => import('./InsuranceDataPolicy'));
const FlowSandBox = lazy(() => import('./FlowSandBox'));
const CreditCheckMyself = lazy(() => import('./CreditCheckMyself'));
const MyDebt = lazy(() => import('./MyDebt'));
const MyEconomy = lazy(() => import('./MyEconomy'));
const InterestRate = lazy(() => import('./InterestRate'));
const RepaymentPeriod = lazy(() => import('./RepaymentPeriod'));
const CreditCardLoan = lazy(() => import('./CreditCardLoan'));
const SmallLoan = lazy(() => import('./SmallLoan'));
const DebtFree = lazy(() => import('./DebtFree'));
const DebtOverview = lazy(() => import('./DebtOverview'));
const CompareLoans = lazy(() => import('./CompareLoans'));
const Microloan = lazy(() => import('./Microloan'));
const VerifiedRefinancing = lazy(() => import('./VerifiedRefinancing'));
const RefinancingLoans = lazy(() => import('./RefinancingLoans'));
const CompareConsumerLoans = lazy(() => import('./CompareConsumerLoans'));
const SaveOrRepayLoan = lazy(() => import('./SaveOrRepayLoan'));
const NominalAndEffectiveInterestRate = lazy(() =>
  import('./NominalAndEffectiveInterestRate'),
);

const Routes = () => {
  const { routes } = LANG;
  return (
    <Suspense fallback={<Loader />}>
      <Switch>
        <Route exact path={routes.HOME} component={Home} />
        <Route exact path={routes.ABOUT} component={About} />
        <Route exact path={routes.TERMS_OF_USE} component={Terms} />
        <Route
          exact
          path={routes.TERMS_OF_USE_MORTGAGE}
          component={TermsMortgage}
        />
        <Route
          exact
          path={routes.TERMS_OF_USE_REFINANCING}
          component={TermsRefinancing}
        />
        <Route
          exact
          path={routes.TERMS_OF_USE_MIN_SCOOPR}
          component={TermsMinScoopr}
        />
        <Route exact path={routes.COOKIES} component={Cookies} />
        <Route exact path={routes.DEBT_REGISTER} component={DebtRegister} />
        <Route exact path={routes.INTEREST_RATE} component={InterestRate} />
        <Route exact path={routes.MICROLOAN} component={Microloan} />
        <Route
          exact
          path={routes.NOMINAL_AND_EFFECTIVE_INTEREST_RATE}
          component={NominalAndEffectiveInterestRate}
        />
        <Route
          exact
          path={routes.REFINANCING_LOANS}
          component={RefinancingLoans}
        />
        <Route
          exact
          path={routes.REPAYMENT_PERIOD}
          component={RepaymentPeriod}
        />

        <Route exact path={routes.ERROR_PAGE} component={ErrorPage} />
        <Route exact path={routes.DEBT_FREE} component={DebtFree} />
        <Route exact path={routes.DEBT_OVERVIEW} component={DebtOverview} />
        <Route exact path={routes.COMPARE_LOANS} component={CompareLoans} />
        <Route
          exact
          path={routes.COMPARE_CONSUMER_LOANS}
          component={CompareConsumerLoans}
        />
        <Route
          exact
          path={routes.SAVE_OR_REPAY_LOAN}
          component={SaveOrRepayLoan}
        />
        <Route
          exact
          path={routes.CREDIT_CARD_LOAN}
          component={CreditCardLoan}
        />
        <Route exact path={routes.SMALL_LOAN} component={SmallLoan} />
        <Route
          exact
          path={routes.DEBT_INFORMATION}
          component={DebtInformation}
        />
        <Route exact path={routes.MY_ECONOMY} component={MyEconomy} />

        <Route
          exact
          path={routes.CREDIT_CHECK_MYSELF_CHAT}
          component={CreditCheckMyself}
        />
        <Route
          exact
          path={routes.CREDIT_CHECK_MYSELF}
          component={CreditCheckMyself}
        />
        <Route exact path={routes.PRIVACY} component={Privacy} />
        <Route
          exact
          path={routes.PRIVACY_STATEMENT}
          component={PrivacyStatement}
        />
        <Route exact path={routes.MY_DEBT} component={MyDebt} />
        <Route
          exact
          path={routes.PARTNER_BANK_DETAILS}
          component={PartnerBankDetails}
        />
        <Route exact path={routes.PARTNER_BANKS} component={PartnerBanks} />
        <Route
          exact
          path={routes.CUSTOMER_SERVICE}
          component={CustomerService}
        />
        <Route
          exact
          path={routes.PRODUCTS_REFINANCING_CHAT}
          component={Refinancing}
        />
        <Route exact path={routes.LIVE_OFFERS} component={LiveOffers} />
        <Route
          exact
          path={routes.ABANDONED_SESSIONS_CHAT}
          component={Refinancing}
        />
        <Route
          exact
          path={routes.PRODUCTS_REFINANCING}
          component={Refinancing}
        />
        <Route exact path={routes.PRODUCTS_LOAN} component={Loan} />
        <Route exact path={routes.PRODUCTS_INSURANCE} component={Insurance} />
        <Route exact path={routes.FLOW_SANDBOX} component={FlowSandBox} />
        <Route
          exact
          path={routes.INSURANCE_DATA_POLICY}
          component={InsuranceDataPolicy}
        />
        <Route
          path={routes.VERIFIED_REFINANCING}
          component={VerifiedRefinancing}
        />
        <Route
          exact
          path={routes.PRODUCTS_FAQ_ARTICLE}
          component={FaqArticle}
        />
        <ProtectedMyScooprRoute
          exact
          path={routes.MY_SCOOPR}
          component={MyScoopr}
        />
        <ProtectedMyScooprRoute
          exact
          path={routes.MY_SCOOPR_CONSENTS}
          component={ConsentsMyScoopr}
          name="consents"
        />
        <Route exact path={routes.MY_SCOOPR_LOGIN} component={Login} />
        <ProtectedMyScooprRoute
          exact
          path={routes.MY_SCOOPR_MY_CONSENTS}
          component={MyConsentsMyScoopr}
        />
        <ProtectedMyScooprRoute
          exact
          path={routes.MY_SCOOPR_MY_DEBT}
          component={MyDebtMyScoopr}
        />
        <ProtectedMyScooprRoute
          exact
          path={routes.MY_SCOOPR_EMAIL}
          component={EmailMyScoopr}
          name="email"
        />
        <ProtectedMyScooprRoute
          exact
          path={routes.MY_SCOOPR_MY_CREDIT_SCORE}
          component={MyCreditScoreMyScoopr}
        />
        <ProtectedMyScooprRoute
          exact
          path={routes.MY_SCOOPR_SAVINGS_POTENTIAL}
          component={SavingsPotentialMyScoopr}
        />
        <Route
          exact
          path={routes.MY_SCOOPR_LOGIN_LOADING}
          component={LoginRedirectMyScoopr}
        />
        <ProtectedMyScooprRoute
          exact
          path={routes.MY_SCOOPR_DASHBOARD}
          component={Dashboard}
        />
        <Redirect
          from={routes.CHEAPEST_LOAN}
          to={routes.PRODUCTS_REFINANCING}
        />
        <Redirect
          from={routes.UNSECURED_LOAN}
          to={routes.PRODUCTS_REFINANCING}
        />
        <Redirect
          from={routes.BEST_CONSUMER_LOAN}
          to={routes.PRODUCTS_REFINANCING}
        />
        <Redirect
          from={routes.LOAN_CALCULATOR}
          to={routes.PRODUCTS_REFINANCING}
        />
        <Redirect
          from={routes.CONSUMER_LOAN_WITH_PAYMENT_REMARK}
          to={routes.PRODUCTS_REFINANCING}
        />
        <Redirect from={routes.LOAN_MONEY} to={routes.PRODUCTS_REFINANCING} />
        <Redirect from={routes.ABOUT_LOAN} to={routes.PRODUCTS_REFINANCING} />
        <Redirect from={routes.PRIVATE_LOAN} to={routes.PRODUCTS_REFINANCING} />
        <Route path="*" component={NoMatch} />
      </Switch>
    </Suspense>
  );
};

export default Routes;

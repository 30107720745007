import makeStyles from '@mui/styles/makeStyles';

export default makeStyles(({ palette }) => ({
  loaderContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    height: '100%',
    width: '100%',
  },
  topBox: {
    marginTop: '-80px',
  },
  description: {
    maxWidth: 380,
    lineHeight: '1.5rem',
    margin: '30px 0 42px 0',
  },
  circlesContainer: {
    position: 'relative',
    width: 140,
    height: 140,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  innerCircle: {
    position: 'absolute',
    border: `6px solid ${palette.background.contrast}`,
    width: 25,
    height: 25,
    borderRadius: '50%',
  },
  circle: {
    fill: 'transparent',
    position: 'absolute',
  },
  circle1: {
    fontSize: '3rem',
    color: palette.primary.main,
    transform: 'translate(-50%, -50%)',
  },
  circle2: {
    fontSize: '4.5rem',
    color: palette.accent.main,
    transform: 'translate(-50%, -50%) rotate(180deg)',
  },
  circle3: {
    fontSize: '6rem',
    color: palette.secondary.main,
    transform: 'translate(-50%, -50%) rotate(90deg)',
  },
  circle4: {
    fontSize: '7.6rem',
    color: palette.accent.main,
    transform: 'translate(-50%, -50%) rotate(40deg)',
  },
  circle5: {
    fontSize: '9.2rem',
    color: palette.primary.main,
    transform: 'translate(-50%, -50%) rotate(-40deg)',
  },

  circleRotateRight: {
    animation: '$rotateRight 3s linear infinite',
  },
  circleRotateLeft: {
    animation: '$rotateLeft 3s linear infinite',
  },
  '@keyframes rotateRight': {
    from: { transform: 'rotate(0deg)' },
    to: { transform: 'rotate(360deg)' },
  },
  '@keyframes rotateLeft': {
    from: { transform: 'rotate(360deg)' },
    to: { transform: 'rotate(0deg)' },
  },
}));

import React from 'react';
import PropTypes from 'prop-types';
import { Container } from '@mui/material';

// import HeaderVariants from '../ABTesting/HeaderVariants/HeaderVariants';

import useStyles from './ChatHeader.styles';
import SimpleChatHeader from './_components/SimpleChatHeader';

const ChatHeader = ({
  handleAction,
  closeDisabled,
  backButtonAction,
  canGoBack,
  animation,
}) => {
  const classes = useStyles();
  return (
    <div className={classes.chatHeader}>
      <Container maxWidth="sm">
        <SimpleChatHeader
          handleAction={handleAction}
          withClose={!closeDisabled}
          backButtonAction={backButtonAction}
          canGoBack={canGoBack}
          animation={animation}
        />
        {/* <HeaderVariants handleAction={handleAction} progress={progress} /> */}
      </Container>
    </div>
  );
};

ChatHeader.propTypes = {
  handleAction: PropTypes.func.isRequired,
  closeDisabled: PropTypes.bool,
  canGoBack: PropTypes.bool,
  animation: PropTypes.bool,
};

ChatHeader.defaultProps = {
  closeDisabled: false,
  canGoBack: false,
};

export default ChatHeader;

import makeStyles from '@mui/styles/makeStyles';

export default makeStyles(({ palette }) => ({
  transparent: {
    color: palette.chat.button.transparent.text,
    border: `2px solid ${palette.chat.button.transparent.border}`,
    backgroundColor: 'transparent',
    '&:hover': {
      color: palette.common.white,
      backgroundColor: palette.chat.button.transparent.border,
      textDecoration: 'none',
    },
  },
}));

import React from 'react';
import clsx from 'clsx';
import { Button } from '@mui/material';
import PropTypes from 'prop-types';
import HtmlParser from 'react-html-parser';

import useBrainSession from '../../services/BrainSession/useBrainSession';
import useTracking from '../../services/Tracking/useTracking';

import useStyles from './ActionButton.styles';

const ActionButton = ({
  label,
  step,
  flow,
  clientKnowledge,
  color,
  gaEvent,
  gaParameters,
  className,
  size,
  onClick,
  ...props
}) => {
  const classes = useStyles();
  const { toggleChat } = useBrainSession();
  const { addEvent } = useTracking();

  return (
    <Button
      variant="contained"
      size={size || 'large'}
      className={clsx(color === 'transparent' && classes[color], className)}
      color={color === 'transparent' ? 'inherit' : color}
      onClick={() => {
        if (gaEvent) {
          addEvent(gaEvent, gaParameters);
        }
        if (onClick) {
          onClick();
          return;
        }
        toggleChat({
          goal: flow,
          step,
          clientKnowledge,
        });
      }}
      {...props}
    >
      {HtmlParser(label)}
    </Button>
  );
};

ActionButton.propTypes = {
  label: PropTypes.string,
  flow: PropTypes.string,
  step: PropTypes.string,
  clientKnowledge: PropTypes.any,
  color: PropTypes.oneOf(['secondary', 'primary', 'transparent', 'default']),
  gaEvent: PropTypes.string,
  gaParameters: PropTypes.any,
  className: PropTypes.string,
  size: PropTypes.string,
  onClick: PropTypes.func,
};

ActionButton.defaultProps = {
  label: '',
  flow: '',
  step: '',
  clientKnowledge: {},
  color: 'secondary',
  size: 'large',
  gaEvent: null,
  gaParameters: null,
  className: undefined,
  onClick: undefined,
};

export default ActionButton;

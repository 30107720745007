import makeStyles from '@mui/styles/makeStyles';

export default makeStyles(({ palette, spacing, typography }) => ({
  bubbleContainer: {
    display: 'flex',
    alignSelf: 'flex-start',
    justifyContent: 'center',
    backgroundColor: palette.chat.bubble.scoopr.background,
    padding: spacing(2),
    borderRadius: 8,
    boxShadow: '0px 1px 3px rgba(24, 160, 251, 0.12)',
    marginBottom: spacing(2.5),
  },
  bubbleContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    color: palette.chat.bubble.scoopr.text,
    ...typography.p1,

    paddingRight: spacing(1),
    maxHeight: '35vh',
    overflowY: 'auto',
    overflowX: 'hidden',
    '&::-webkit-scrollbar': {
      width: 3,
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: 3,
      backgroundColor: 'rgba(0,0,0,.25)',
    },

    '& > p': {
      margin: 0,
    },
    '& > h3': {
      marginTop: 0,
    },
    '& a': {
      color: palette.text.primary,
      textDecoration: 'none',
      '&:hover': {
        cursor: 'pointer',
        textDecoration: 'underline',
      },
    },
    '& ul': {
      marginTop: spacing(1),
      paddingLeft: spacing(3),
    },
    '& li': {
      margin: spacing(0.5, 0),
    },
    '& > :last-child': {
      marginBottom: 0,
    },
  },
}));

import React, { useState, Suspense } from 'react';

import Loader from '../Loader';
import Routes from '../../pages/Routes';
import ChatContainer from '../Chat/_components/ChatContainer';
import InfoBox from '../InfoBox';
import useAuth from '../../services/Auth/useAuth';
import useIsMyScoopr from '../../hooks/IsMyScoopr';
import useIsMobile from '../../hooks/IsMobile';
import useBrainSession from '../../services/BrainSession/useBrainSession';

import SideBar from './_components/SideBar';
import TopBar from './_components/TopBar';
import Footer from './_components/Footer';
import useStyles from './Layout.styles';
import data from './data.json';
import MinScooprMenu from './_components/MinScooprMenu';

const DashboardLayout = () => {
  const classes = useStyles();
  const { authData } = useAuth();
  const { isMyScoopr } = useIsMyScoopr();
  const { isMobile } = useIsMobile();
  const { showChatContainer } = useBrainSession();
  const [isSideBarOpen, setSideBarOpen] = useState(false);

  return (
    <Suspense fallback={<Loader />}>
      {showChatContainer ? (
        <div className={classes.root}>
          <ChatContainer />
        </div>
      ) : (
        <div className={classes.root}>
          <TopBar
            setSideBarOpen={() => setSideBarOpen(!isSideBarOpen)}
            isSideBarOpen={isSideBarOpen}
            data={data}
          />
          <SideBar
            onSideBarClose={() => setSideBarOpen(false)}
            isSideBarOpen={isSideBarOpen}
            data={data}
          />
          <InfoBox />

          <div className={classes.wrapper}>
            <div className={classes.contentContainer} role="main">
              <div className={classes.content}>
                {authData.isAuthInit ? <Routes /> : <Loader />}
              </div>
            </div>
          </div>
          {isMyScoopr && isMobile ? <MinScooprMenu /> : <Footer />}
        </div>
      )}
    </Suspense>
  );
};

export default DashboardLayout;

import React from 'react';
import PropTypes from 'prop-types';
import { m as lazyMotion } from 'framer-motion';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';

import useStyles from './ScooprBubble.styles';

export const variants = {
  hidden: {
    opacity: 0,
    y: 0,
  },
  visible: {
    y: 0,
    opacity: 1,
    transition: {
      ease: 'easeInOut',
      damping: 8,
      stiffness: 1000,
      velocity: -100,
    },
  },
  exit: {
    y: '-100vh',
    opacity: 0,
    transition: {
      duration: 0.5,
      ease: 'easeInOut',
      stiffness: 1000,
    },
  },
};

const ScooprBubble = ({ utterance, classes: parentClasses }) => {
  const classes = useStyles({ classes: parentClasses });

  return (
    <lazyMotion.div className={classes.bubbleContainer} variants={variants}>
      <div className={classes.bubbleContent}>
        <ReactMarkdown children={utterance} rehypePlugins={[rehypeRaw]} />
      </div>
    </lazyMotion.div>
  );
};

ScooprBubble.propTypes = {
  utterance: PropTypes.string.isRequired,
  classes: PropTypes.any,
};

ScooprBubble.defaultProps = {
  classes: undefined,
};

export default ScooprBubble;

import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import LogoIcon from '../../assets/icons/LogoIcon';

import useStyles from './Logo.styles';

const Logo = ({ className, ...rest }) => {
  const classes = useStyles();
  return <LogoIcon className={clsx(classes.logoRoot, className)} {...rest} />;
};

Logo.propTypes = {
  className: PropTypes.string,
};

Logo.defaultProps = {
  className: '',
};

export default Logo;

import React, { Suspense, lazy } from 'react';
import PropTypes from 'prop-types';

import Loader from '../../../Loader';
const ChatRadioDynamicButtons = lazy(() =>
  import('./_components/ChatRadioDynamicButtons'),
);

const ChatRadioButtons = lazy(() => import('./_components/ChatRadioButtons'));
const ChatLoansPreviewInterest = lazy(() =>
  import('./_components/ChatLoansPreviewInterest'),
);

const ChatADAApplicationLoader = lazy(() =>
  import('./_components/ChatADAApplicationLoader'),
);

const ChatRegisterDebtFullControlTableBottom = lazy(() =>
  import('./_components/ChatRegisterDebtFullControlTableBottom'),
);
const ChatDebtConsentWithoutLogos = lazy(() =>
  import('./_components/ChatDebtConsentWithoutLogos'),
);
const ChatDebtConsentAccountCreation = lazy(() =>
  import('./_components/ChatDebtConsentAccountCreation'),
);
const ChatRegisterDebtFullControl = lazy(() =>
  import('./_components/ChatRegisterDebtFullControl'),
);
const ChatLoansPreviewTableBottom = lazy(() =>
  import('./_components/ChatLoansPreviewTableBottom'),
);

const ChatLoansPreview2 = lazy(() => import('./_components/ChatLoansPreview2'));

const ChatDebtConsent = lazy(() => import('./_components/ChatDebtConsent'));

const ChatDebtRegisterLoader = lazy(() =>
  import('./_components/ChatDebtRegisterLoader'),
);

const ChatGoogleOptimize = lazy(() =>
  import('./_components/ChatGoogleOptimize'),
);

const ChatActionSuccess = lazy(() => import('./_components/ChatActionSuccess'));
const ChatActionError = lazy(() => import('./_components/ChatActionError'));
const ChatDefaultInput = lazy(() => import('./_components/ChatDefaultInput'));
const ChatInsuranceOffer = lazy(() =>
  import('./_components/ChatInsuranceOffer'),
);
const ChatDefaultButtons = lazy(() =>
  import('./_components/ChatDefaultButtons'),
);
const ChatProductButtons = lazy(() =>
  import('./_components/ChatProductButtons'),
);
const ChatADARedirectLoader = lazy(() =>
  import('./_components/ChatADARedirectLoader'),
);
const ChatConsent = lazy(() => import('./_components/ChatConsent'));
const ChatCarousel = lazy(() => import('./_components/ChatCarousel'));
const ChatAuthSms = lazy(() => import('./_components/ChatAuthSms'));
const ChatIframe = lazy(() => import('./_components/ChatIframe'));
const ChatButtonsInput = lazy(() => import('./_components/ChatButtonsInput'));
const ChatDebtConsentWithLogos = lazy(() =>
  import('./_components/ChatDebtConsentWithLogos'),
);
const ChatPotentialSavings = lazy(() =>
  import('./_components/ChatPotentialSavings'),
);
const ChatPotentialSavings2 = lazy(() =>
  import('./_components/ChatPotentialSavings2'),
);
const ChatPotentialSavings3 = lazy(() =>
  import('./_components/ChatPotentialSavings3'),
);
const ChatPotentialSavings4 = lazy(() =>
  import('./_components/ChatPotentialSavings4'),
);
const ChatMobileConsent = lazy(() => import('./_components/ChatMobileConsent'));
const ChatAuthMobileConsent = lazy(() =>
  import('./_components/ChatAuthMobileConsent'),
);
const ChatInsuranceProviders = lazy(() =>
  import('./_components/ChatInsuranceProviders'),
);
const ChatGoScoreDownload = lazy(() =>
  import('./_components/ChatGoScoreDownload'),
);
const ChatGoScoreQuestionsFilled = lazy(() =>
  import('./_components/ChatGoScoreQuestionsFilled'),
);
const ChatGoScoreTermsConditions = lazy(() =>
  import('./_components/ChatGoScoreTermsConditions'),
);
const ChatCreditsafeLoader = lazy(() =>
  import('./_components/ChatCreditsafeLoader'),
);
const ChatCreditsafeScore = lazy(() =>
  import('./_components/ChatCreditsafeScore'),
);
const ChatCreditsafeConsent = lazy(() =>
  import('./_components/ChatCreditsafeConsent'),
);
const ChatLoansPreview = lazy(() => import('./_components/ChatLoansPreview'));

const components = {
  defaultButtons: ChatDefaultButtons,
  defaultInput: ChatDefaultInput,
  productButtons: ChatProductButtons,
  consent: ChatConsent,
  carousel: ChatCarousel,
  authSms: ChatAuthSms,
  iframe: ChatIframe,
  buttonsInput: ChatButtonsInput,
  potentialSavings: ChatPotentialSavings,
  potentialSavings2: ChatPotentialSavings2,
  potentialSavings3: ChatPotentialSavings3,
  potentialSavings4: ChatPotentialSavings4,
  mobileConsent: ChatMobileConsent,
  mobileAuthConsent: ChatAuthMobileConsent,
  insuranceOffer: ChatInsuranceOffer,
  actionSuccess: ChatActionSuccess,
  actionError: ChatActionError,
  insuranceProviders: ChatInsuranceProviders,
  goScoreDownload: ChatGoScoreDownload,
  goScoreQuestionsFilled: ChatGoScoreQuestionsFilled,
  goScoreTermsConditions: ChatGoScoreTermsConditions,
  ADARedirectLoader: ChatADARedirectLoader,
  creditsafeLoader: ChatCreditsafeLoader,
  creditsafeScore: ChatCreditsafeScore,
  creditsafeConsent: ChatCreditsafeConsent,
  loansPreview: ChatLoansPreview,
  googleOptimize: ChatGoogleOptimize,
  debtRegisterLoader: ChatDebtRegisterLoader,
  loansPreview2: ChatLoansPreview2,
  debtConsent: ChatDebtConsent,
  debtConsentWithLogos: ChatDebtConsentWithLogos,
  debtConsentWithoutLogos: ChatDebtConsentWithoutLogos,
  debtConsentAccountCreation: ChatDebtConsentAccountCreation,
  registerDebtFullControl: ChatRegisterDebtFullControl,
  loansPreviewTableBottom: ChatLoansPreviewTableBottom,
  registerDebtFullControlTableBottom: ChatRegisterDebtFullControlTableBottom,
  adaApplicationLoader: ChatADAApplicationLoader,
  radioButtons: ChatRadioButtons,
  loansPreviewInterest: ChatLoansPreviewInterest,
  radioDynamicButtons: ChatRadioDynamicButtons,
};

const ChatAnswers = ({ handleAction, data, chatOptions }) => {
  const Component = components[data.answerType || 'defaultButtons'];

  return (
    <Suspense fallback={<Loader inline />}>
      <Component
        handleAction={handleAction}
        data={data.suggestedResponses}
        parameters={data.parameters}
        extraParameters={data.extra_parameters}
        utterance={data.utterance}
        chatOptions={chatOptions}
      />
    </Suspense>
  );
};

ChatAnswers.propTypes = {
  handleAction: PropTypes.func.isRequired,
  data: PropTypes.any,
  chatOptions: PropTypes.any,
};

ChatAnswers.defaultProps = {
  data: null,
  chatOptions: null,
};

export default ChatAnswers;

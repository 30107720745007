import makeStyles from '@mui/styles/makeStyles';

import { SIZES } from '../../../../assets/constants/constants';

export default makeStyles(({ breakpoints, palette, spacing }) => ({
  chatHeader: {
    boxShadow: '0px 3px 13px -7px #ccc, 0px 2px 0px -10px rgba(0,0,0,0)',
    position: 'fixed',
    backgroundColor: palette.background.default,
    width: '100%',
    zIndex: 9900, // have to be higher than bubbles' going up animation
  },
  chatHeaderContent: {
    height: SIZES.topBar.height.small,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    position: 'relative',
    [breakpoints.up(SIZES.navigationChange)]: {
      height: SIZES.topBar.height.large,
    },
  },
  root: {
    width: '100%',
  },
  progress: {
    position: 'absolute',
    left: 0,
    bottom: 10,
    width: '100%',
  },
  counter: {
    display: 'flex',
    flex: 1,
    fontSize: 15,
    [breakpoints.up(SIZES.navigationChange)]: {
      fontSize: '1.1rem',
    },
  },
  logo: {
    display: 'flex',
    fill: palette.primary.main,
    marginTop: spacing(0.5),
    [breakpoints.up(SIZES.navigationChange)]: {
      height: 25,
    },
  },
  logoWithProgress: {
    flex: 2,
  },
  icon: {
    display: 'flex',
    flex: 1,
    justifyContent: 'flex-end',
  },
  iconPlaceholder: {
    width: 40,
    height: 40,
  },
}));

import makeStyles from '@mui/styles/makeStyles';

export default makeStyles(({ palette, spacing, typography }) => ({
  navItemText: {
    '& > span': {
      fontSize: '1.125rem',
      fontWeight: typography.fontWeightSemiBold,
    },
  },
  menuItem: {
    display: 'flex',
    color: palette.text.default,
    justifyContent: 'flex-start',
    padding: spacing(1.5, 8),
    width: '100%',
  },
  menuItemActive: {
    color: palette.text.primary,
  },
}));

import React, { useEffect, useRef, useState } from 'react';
import { Close } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { m as lazyMotion } from 'framer-motion';

import Typography from '../Typography';

import data from './data.json';
// import useGetInfoBoxQuery from '../../hooks/Gql/GetInfoBox.query';
import useStyles from './InfoBox.styles';

const variants = {
  hidden: {
    opacity: 0,
    transition: {
      damping: 2,
      ease: 'easeInOut',
      velocity: -100,
    },
  },
  visible: {
    opacity: 1,
    transition: {
      ease: 'easeInOut',
      damping: 8,
      velocity: -100,
    },
  },
};

const InfoBox = () => {
  // const { data, error, loading } = useGetInfoBoxQuery();
  const loading = false;
  const error = null;

  const [isOpen, setIsOpen] = useState(true);
  const ref = useRef();
  const [offsetTop, setOffsetTop] = useState(0);
  const close = () => {
    setOffsetTop(0);
    setIsOpen(false);
  };

  const calculateTopOffset = () => {
    return ref?.current ? ref.current.offsetTop + ref.current.offsetHeight : 0;
  };

  useEffect(() => {
    if (!error && !loading && data?.isActive) {
      setIsOpen(true);
      const val = calculateTopOffset();
      setOffsetTop(val);
    }
  }, [error, loading]);

  useEffect(() => {
    if (isOpen) {
      const set = () => {
        const val = calculateTopOffset();
        setOffsetTop(val);
      };
      window.addEventListener('resize', set);
      return () => window.removeEventListener('resize', set);
    }
    return () => null;
  }, [isOpen]);

  const classes = useStyles({ offsetTop });

  return (
    <>
      {data.isActive && isOpen ? (
        <lazyMotion.div
          ref={ref}
          variants={variants}
          initial="hidden"
          animate={isOpen ? 'visible' : 'hidden'}
          className={classes.infoBoxContainer}
        >
          <div className={classes.infoBoxContent}>
            <span className={classes.iconConatiner}>i</span>
            <Typography variant="p3SemiBold" className={classes.text}>
              {data.text}
            </Typography>
            <span className={classes.actionContainer}>
              <IconButton onClick={close} size="large">
                <Close />
              </IconButton>
            </span>
          </div>
        </lazyMotion.div>
      ) : null}
      <div className={classes.paddingPlaceholder} />
    </>
  );
};

InfoBox.propTypes = {};

InfoBox.defaultProps = {};

export default InfoBox;

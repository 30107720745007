import React from 'react';
import { Redirect, Route } from 'react-router-dom';

import { LANG } from '../Config';
import Loader from '../../components/Loader';

import useAuth from './useAuth';

function ProtectedMyScooprRoute({
  component: Component,
  name,
  ...restOfProps
}) {
  const { routes } = LANG;
  const { authData } = useAuth();

  const redirectChecker = props => {
    if (authData.isAuth && !authData.email) {
      if (name !== 'email') {
        return <Redirect to={routes.MY_SCOOPR_EMAIL} />;
      } else {
        return <Component {...props} />;
      }
    }
    if (authData.isAuth && !authData.haveConsents) {
      if (name !== 'consents') {
        return <Redirect to={routes.MY_SCOOPR_CONSENTS} />;
      } else {
        return <Component {...props} />;
      }
    }
    return <Redirect to={routes.MY_SCOOPR_LOGIN} />;
  };

  return authData.isAuthInit ? (
    <Route
      {...restOfProps}
      render={props =>
        authData.isAuth && authData.haveConsents && authData.email ? (
          <Component {...props} />
        ) : (
          redirectChecker(props)
        )
      }
    />
  ) : (
    <Loader />
  );
}

export default ProtectedMyScooprRoute;

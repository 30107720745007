import React, { forwardRef } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Tab } from '@mui/material';

import useStyles from './TopBarItem.styles';

const TopBarItem = forwardRef(({ menuItem, ...props }, ref) => {
  const classes = useStyles();

  const { search } = useLocation();

  return (
    <div className={classes.tabContainer}>
      <Tab
        label={menuItem.title}
        component={RouterLink}
        to={`${menuItem.href}${search}`} // Passing params by search from landing page
        disableRipple
        classes={{
          root: classes.tabRoot,
        }}
        tabIndex={0}
        {...props}
      />
    </div>
  );
});

TopBarItem.propTypes = {
  menuItem: PropTypes.object.isRequired,
};

TopBarItem.defaultProps = {};

export default TopBarItem;

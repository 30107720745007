import axios from 'axios';

import {
  getTokenFromLS,
  setAccessTokenToLS,
  removeTokenFromLS,
} from '../Utils';
import { API } from '../Config';

const baseURL = '';

const HttpService = axios.create({
  baseURL: baseURL,
  timeout: 60000,
});

HttpService.interceptors.request.use(
  config => {
    const token = getTokenFromLS();
    if (token) {
      config.headers['Authorization'] = `Bearer ${token.accessToken}`;
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  },
);

HttpService.interceptors.response.use(
  response => response,
  error => {
    const originalRequest = error.config;

    // Prevent infinite loops
    if (
      error?.response?.status === 401 &&
      originalRequest.url === `${API.url}/authorization/session/token/refresh/`
    ) {
      removeTokenFromLS();
      window.location.href = '/oops';
      return Promise.reject(error);
    }

    if (error?.response?.status === 401) {
      const token = getTokenFromLS();
      if (token && token.refreshToken) {
        const tokenParts = JSON.parse(atob(token.refreshToken.split('.')[1]));

        // exp date in token is expressed in seconds, while now() returns milliseconds:
        const now = Math.ceil(Date.now() / 1000);

        if (tokenParts.exp > now) {
          return HttpService.post(
            `${API.url}/authorization/session/token/refresh/`,
            { refresh: token.refreshToken },
          )
            .then(response => {
              setAccessTokenToLS(response.data.access);

              HttpService.defaults.headers[
                'Authorization'
              ] = `Bearer ${response.data.access}`;
              originalRequest.headers[
                'Authorization'
              ] = `Bearer ${response.data.access}`;

              return HttpService(originalRequest);
            })
            .catch(err => {
              console.log('Refresh token is expired', tokenParts.exp, now);
              removeTokenFromLS();
              HttpService.defaults.headers['Authorization'] = null;
              window.location.href = '/oops';
            });
        } else {
          console.log('Refresh token is expired', tokenParts.exp, now);
          removeTokenFromLS();
          HttpService.defaults.headers['Authorization'] = null;
          window.location.href = '/oops';
        }
      } else {
        console.log('Refresh token not available.');
        window.location.href = '/oops';
      }
    }

    // specific error handling done elsewhere
    return Promise.reject(error);
  },
);

export default HttpService;

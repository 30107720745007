import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import Typography from '../Typography';

import useStyles from './SectionTitle.styles';

const SectionTitle = ({ title, subtitle, color }) => {
  const classes = useStyles();

  if (!title && !subtitle) return null;
  return (
    <>
      {title ? (
        <Typography
          variant="h2"
          className={clsx(
            classes.text,
            subtitle && classes.withSubtitle,
            classes[color],
          )}
        >
          {title}
        </Typography>
      ) : null}
      {subtitle ? (
        <Typography
          variant="subtitle2"
          component="h3"
          className={clsx(classes.text, classes[color])}
        >
          {subtitle}
        </Typography>
      ) : null}
    </>
  );
};

SectionTitle.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  color: PropTypes.oneOf(['default', 'contrast']),
};

SectionTitle.defaultProps = {
  title: null,
  color: 'default',
  subtitle: null,
};

export default SectionTitle;

import React from 'react';

// import PropTypes from 'prop-types';
import Chat from '../../Chat';
import useBrainSession from '../../../../services/BrainSession/useBrainSession';

import useStyles from './ChatContainer.styles';

const ChatContainer = () => {
  const { chatOptions } = useBrainSession();
  const classes = useStyles();

  return (
    <div className={classes.chatWrapper}>
      <Chat
        goal={chatOptions?.goal || ''}
        step={chatOptions?.step || ''}
        firstMessage={chatOptions?.firstMessage || null}
        clientKnowledge={chatOptions?.clientKnowledge || null}
      />
    </div>
  );
};

ChatContainer.propTypes = {};

ChatContainer.defaultProps = {};

export default ChatContainer;

import makeStyles from '@mui/styles/makeStyles';

import { SIZES } from '../../assets/constants/constants';

export default makeStyles(({ breakpoints, palette }) => ({
  loaderContainer: {
    minHeight: ({ inline }) =>
      inline ? 0 : `calc(100vh - ${SIZES.topBar.height.small}px)`,
    [breakpoints.up(SIZES.navigationChange)]: {
      minHeight: ({ inline }) =>
        inline ? 0 : `calc(100vh - ${SIZES.topBar.height.large}px)`,
    },
  },
  flowDirection: {
    flexDirection: 'column',
    margin: 20,
    minHeight: ({ inline }) =>
      inline ? 0 : `calc(100vh - ${SIZES.topBar.height.small}px)`,
    [breakpoints.up(SIZES.navigationChange)]: {
      minHeight: ({ inline }) =>
        inline ? 0 : `calc(100vh - ${SIZES.topBar.height.large}px)`,
    },
  },
  loaderText: {
    display: 'block',
    marginTop: 10,
    marginBottom: 10,
    color: palette.neutral.medium,
  },
}));

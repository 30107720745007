import React from 'react';
import PropTypes from 'prop-types';
import { Drawer, List } from '@mui/material';

import { getMenuItems } from '../../../../assets/constants/constants';
import { LANG } from '../../../../services/Config';
import MenuActionButton from '../MenuActionButton';

import NavItem from './_components/NavItem';
import useStyles from './SideBar.styles';

// different menu structure than on topbar
const prepareMenuItems = (appStrings, routes) => {
  return [
    ...getMenuItems(appStrings, routes),
    {
      title: appStrings.MENU.INTEREST_RATE,
      href: routes.INTEREST_RATE,
    },
    {
      title: appStrings.MENU.SMALL_LOAN,
      href: routes.SMALL_LOAN,
    },
  ];
};

const SideBar = ({ onSideBarClose, isSideBarOpen, data }) => {
  const classes = useStyles();
  const { appStrings, routes } = LANG;

  return (
    <Drawer
      anchor="left"
      variant="temporary"
      classes={{ paper: classes.drawerPaper }}
      className={classes.drawer}
      onClose={onSideBarClose}
      open={isSideBarOpen}
      BackdropProps={{
        invisible: true,
      }}
    >
      <div className={classes.drawerContent}>
        <List component="nav">
          {prepareMenuItems(appStrings, routes, data).map(item => (
            <NavItem
              key={item.title}
              menuItem={item}
              onClick={() => {
                onSideBarClose();
              }}
            />
          ))}
        </List>

        <div className={classes.buttons}>
          <MenuActionButton
            sideBar
            onClick={() => {
              onSideBarClose();
            }}
          />
        </div>
      </div>
    </Drawer>
  );
};

SideBar.propTypes = {
  onSideBarClose: PropTypes.func,
  isSideBarOpen: PropTypes.bool,
  data: PropTypes.any,
};

SideBar.defaultProps = {
  onSideBarClose: () => {},
  isSideBarOpen: false,
  data: null,
};

export default SideBar;

import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

export default function useIsMyScoopr() {
  const [isMyScoopr, setIsMyScoopr] = useState(false);
  const location = useLocation();

  useEffect(() => {
    if (location.pathname.search('/min-scoopr') >= 0 && !isMyScoopr) {
      setIsMyScoopr(true);
    }
    if (location.pathname.search('/min-scoopr') === -1 && isMyScoopr) {
      setIsMyScoopr(false);
    }
  }, [location]);

  return { isMyScoopr };
}

export const HERO_VARIANT = {
  BLUE: 'blue',
  YELLOW: 'yellow',
  RED: 'red',
};

export default function useHero(data) {
  const getHeroColor = () => {
    const colorFromParams = new URLSearchParams(window.location.search).get(
      'hc',
    );
    return colorFromParams &&
      Object.prototype.hasOwnProperty.call(
        HERO_VARIANT,
        colorFromParams.toUpperCase(),
      )
      ? colorFromParams
      : data?.campaignHeroDefaultColor?.toLowerCase() || 'blue';
  };

  const isHeroCampaignActive = Boolean(data?.campaignHeroToggle);
  const heroColor = isHeroCampaignActive ? getHeroColor() : undefined;

  return { heroColor };
}

const customTypographyStyles = {
  subtitle1: {
    fontFamily: "'Nunito', sans-serif",
    fontSize: '1.125rem', // 18px
    lineHeight: 1.4,
    '@media (min-width:600px)': {
      fontSize: '1.5rem', // 24px
    },
  },
  subtitle2: {
    fontFamily: "'Nunito', sans-serif",
    fontSize: '1rem', // 16px
    lineHeight: 1.4,
    '@media (min-width:600px)': {
      fontSize: '1.375rem', // 22px
    },
  },

  p1: {
    fontFamily: "'Nunito', sans-serif",
    fontSize: '1rem', // 16px
    lineHeight: 1.4,
    '@media (min-width:600px)': {
      fontSize: '1.25rem', // 20px
    },
  },
  p2: {
    fontFamily: "'Nunito', sans-serif",
    fontSize: '0.875rem', // 14px
    lineHeight: 1.4,
    '@media (min-width:600px)': {
      fontSize: '1.125rem', // 18px
    },
  },
  p3: {
    fontFamily: "'Nunito', sans-serif",
    fontSize: '0.875rem', // 14px
    lineHeight: 1.4,
    '@media (min-width:600px)': {
      fontSize: '1rem', // 16px
    },
  },
  p4: {
    fontFamily: "'Nunito', sans-serif",
    fontSize: '0.75rem', // 12px
    lineHeight: 1.4,
    '@media (min-width:600px)': {
      fontSize: '0.875rem', // 14px
    },
  },
};

export const customTypography = {
  subtitle1Bold: {
    ...customTypographyStyles.subtitle1,
    fontWeight: 600,
    '@media (min-width:600px)': {
      ...customTypographyStyles.subtitle1,
      fontWeight: 700,
    },
  },
  subtitle2Bold: {
    ...customTypographyStyles.subtitle2,
    fontWeight: 600,
    '@media (min-width:600px)': {
      ...customTypographyStyles.subtitle2,
      fontWeight: 700,
    },
  },

  p1: {
    color: '#031839',
    ...customTypographyStyles.p1,
    fontWeight: 400,
  },
  p1SemiBold: {
    ...customTypographyStyles.p1,
    fontWeight: 600,
  },

  p2: {
    ...customTypographyStyles.p2,
    fontWeight: 400,
  },
  p2SemiBold: {
    ...customTypographyStyles.p2,
    fontWeight: 600,
  },

  p3: {
    ...customTypographyStyles.p3,
    fontWeight: 400,
  },
  p3SemiBold: {
    ...customTypographyStyles.p3,
    fontWeight: 600,
  },
  p3ExtraBold: {
    ...customTypographyStyles.p3,
    fontWeight: 800,
  },

  p4: {
    ...customTypographyStyles.p4,
    fontWeight: 400,
  },
  p4SemiBold: {
    ...customTypographyStyles.p4,
    fontWeight: 600,
  },

  button1: {
    fontFamily: "'Nunito', sans-serif",
    fontWeight: 600,
    fontSize: '1.125rem', // 18px
    lineHeight: 1.4,
    '@media (min-width:600px)': {
      fontSize: '1.25rem', // 20px
    },
  },
  button2: {
    fontFamily: "'Nunito', sans-serif",
    fontWeight: 600,
    fontSize: '1rem', // 16px
    lineHeight: 1.4,
    '@media (min-width:600px)': {
      fontSize: '1.125rem', // 18px
    },
  },
  button3: {
    fontFamily: "'Nunito', sans-serif",
    fontWeight: 600,
    fontSize: '0.875rem', // 14px
    lineHeight: 1.4,
    '@media (min-width:600px)': {
      fontSize: '1rem', // 16px
    },
  },
};

export default {
  fontFamily: "'Nunito', sans-serif",
  fontWeightLight: undefined,
  fontWeightRegular: 400,
  fontWeightMedium: undefined,
  fontWeightSemiBold: 600,
  fontWeightBold: 700,
  fontWeightExtraBold: 800,
  color: '#031839',
  h1: {
    fontWeight: 700,
    fontSize: '1.625rem', // 26px
    lineHeight: 1.4,
    color: '#031839',
    '@media (min-width:600px)': {
      fontSize: '3.5rem', // 56px
    },
  },
  h2: {
    fontWeight: 700,
    fontSize: '1.375rem', // 22px
    lineHeight: 1.4,
    color: '#031839',
    '@media (min-width:600px)': {
      fontSize: '2.25rem', // 36px
    },
  },
  h3: {
    fontWeight: 700,
    fontSize: '1.125rem', // 18px
    lineHeight: 1.4,
    color: '#031839',
    '@media (min-width:600px)': {
      fontSize: '1.75rem', // 28px
    },
  },
  subtitle1: {
    fontWeight: 400,
    ...customTypographyStyles.subtitle1,
  },
  subtitle2: {
    fontWeight: 400,
    ...customTypographyStyles.subtitle2,
  },
  ...customTypography,
  // remove Mui styling
  h4: null,
  h5: null,
  h6: null,
  caption: {
    fontSize: '0.875rem',
  },
  overline: null,
  body1: null,
  body2: null,
  button: null,
};

import React from 'react';
import PropTypes from 'prop-types';
import { m as lazyMotion } from 'framer-motion';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';

import useStyles from './UserBubble.styles';

const variants = {
  hidden: {
    opacity: 0,
    y: 0,
  },
  visible: {
    y: 0,
    opacity: 1,
    transition: {
      ease: 'easeInOut',
      damping: 8,
      stiffness: 1000,
      velocity: -100,
    },
  },
  exit: {
    y: '-100vh',
    opacity: 0,
    transition: {
      duration: 0.5,
      ease: 'easeInOut',
      stiffness: 1000,
    },
  },
};

const UserBubble = ({ utterance }) => {
  const classes = useStyles();

  return (
    <lazyMotion.div className={classes.bubbleContainer} variants={variants}>
      <div className={classes.bubbleContent}>
        <ReactMarkdown children={utterance} rehypePlugins={[rehypeRaw]} />
      </div>
    </lazyMotion.div>
  );
};

UserBubble.propTypes = {
  utterance: PropTypes.any.isRequired,
};

UserBubble.defaultProps = {};

export default UserBubble;

import React, { createContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { sha256 } from 'js-sha256';
import isEmpty from 'lodash/isEmpty';
import Cookies from 'js-cookie';

import { DOMAIN, API, GTM } from '../Config';
import useLocalStorage from '../../hooks/LocalStorage';
import Http, { transformers } from '../Http';
import { getUserAgent, useQueryParams } from '../Utils';
import useTracking from '../Tracking/useTracking';
import useSessionStorage from '../../hooks/SessionStorage';

import { getSourceFormLocation } from './brainSessionService';

const BrainSessionContext = createContext(undefined);

function BrainSessionProvider({ children }) {
  const [userId, setUserId] = useLocalStorage(DOMAIN.userId, null);
  const { addValue: gtmAddValue, addEvent, getGaClientId } = useTracking();
  const [loading, setLoading] = useState(true);
  const [showChatContainer, setShowChatContainer] = useState(false);
  const [chatOptions, setChatOptions] = useState(null);
  const [isBobInit, setIsBobInit] = useState(false);
  const insuranceSID = useQueryParams('sid');
  const readpeakCid = useQueryParams('rpcid');
  const [creditScoreRating, setCreditScoreRating] = useState(0);
  const [brainSessionId, setBrainSessionId] = useLocalStorage(
    DOMAIN.brainSessionId,
    null,
  );
  const [closeModalUrl, setCloseModalUrl] = useLocalStorage(
    DOMAIN.closeModalUrl,
    null,
  );
  const [rParams, setRParams] = useSessionStorage(
    DOMAIN.registerSourceSession,
    {},
  );

  const updateCookie = cookie => {
    return Cookies.get(cookie) || '';
  };

  const getVertex = (id, clientKnowledge) => {
    return {
      id: id,
      client_knowledge: clientKnowledge,
      user_agent: getUserAgent(),
      ga_client_id: getGaClientId(),
      rpcid: readpeakCid
        ? readpeakCid
        : rParams?.custom_data?.params?.rpcid || null,
      ga_session_id: updateCookie(GTM.sessionIdCookie),
      //check if not null
      cidb: updateCookie('_ga'),
      gaexp: updateCookie('_gaexp'),
      gcl_au: updateCookie('_gcl_au'),
      gcl_aw: updateCookie('_gcl_aw'),
      gcl_dc: updateCookie('_gcl_dc'),
      fbp: updateCookie('_fbp'),
      fbc: updateCookie('_fbc'),
    };
  };

  const initBob = async () => {
    if (insuranceSID) {
      setBrainSessionId({ id: insuranceSID });
    }
    try {
      const ver = getVertex(
        insuranceSID ? { id: insuranceSID } : brainSessionId,
        null,
      );
      const res = await Http.post(API.bobUri, {
        vertex: {
          ...ver,
        },
        content_type: 'init',
      });
      const { id, user_id: isUser } = res.data.vertex;
      setBrainSessionId(id);
      setUserId(isUser);
      const { customData, sourceName } = getSourceFormLocation();
      const params = {
        entity_id: id.uid,
        slug: isEmpty(rParams) ? sourceName : rParams.slug,
        custom_data: isEmpty(rParams) ? customData : rParams.custom_data,
      };
      if (customData?.params && !isEmpty(customData.params)) {
        params.custom_data.params = {
          ...params.custom_data.params,
          ...customData.params,
        };
      }
      await Http.post(API.registerSource, params);
      setRParams(params);
      if (isUser) {
        addEvent('setUser');
        gtmAddValue({ isUser });
        gtmAddValue({ userId: isUser });
        gtmAddValue({ hashed_user_id: sha256(isUser) });
        gtmAddValue({
          email: `${isUser}@scoopr-snap.no`,
          event: `email_set`,
        });
      }
      setIsBobInit(true);
    } catch (ex) {
      const errorContent = transformers.prepareResponseError(ex);
      console.log('ex', errorContent);
      console.log('ex1', ex);
    }
    const params = new URLSearchParams(window.location.search);
    const flowParam = params.get('flow');
    const stepParam = params.get('step');
    if (flowParam && stepParam) {
      toggleChat({ step: stepParam, goal: flowParam });
    }
    setLoading(false);
  };

  const saveCloseModalUrl = url => {
    setCloseModalUrl(url);
  };

  const toggleChat = options => {
    setChatOptions(options);
    setShowChatContainer(!showChatContainer);
  };

  const closeChatModal = () => {
    setShowChatContainer(false);
    setChatOptions(null);
  };

  useEffect(() => {
    const fetchData = async () => {
      await initBob();
    };
    fetchData();
  }, []);

  const value = {
    isBobInit,
    brainSessionId,
    loading,
    chatOptions,
    showChatContainer,
    setBrainSessionId,
    rParams,
    setRParams,
    closeModalUrl,
    creditScoreRating,
    setCreditScoreRating,
    userId,
    setUserId,
    toggleChat,
    getVertex,
    closeChatModal,
    saveCloseModalUrl,
  };

  return (
    <BrainSessionContext.Provider value={value}>
      {children}
    </BrainSessionContext.Provider>
  );
}

BrainSessionProvider.propTypes = {
  children: PropTypes.node,
};

BrainSessionProvider.defaultProps = {
  children: null,
};

export { BrainSessionContext, BrainSessionProvider };

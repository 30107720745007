import React from 'react';

import { TrackingContext } from './Tracking';

export default function useTracking() {
  const context = React.useContext(TrackingContext);

  if (context === undefined) {
    throw new Error('useTracking must be used within an TrackingProvider');
  }

  return context;
}

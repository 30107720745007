import React, { useRef, useEffect, useState } from 'react';
import { Link as RouterLink, matchPath, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { AppBar, IconButton, Tabs, Toolbar } from '@mui/material';
import clsx from 'clsx';

import { ReactComponent as CloseXIcon } from '../../../../assets/images/close_x.svg';
import { ReactComponent as HamburgerMenuIcon } from '../../../../assets/images/hamburger_menu.svg';
import Logo from '../../../../assets/images/logo.svg';
import { getMenuItems } from '../../../../assets/constants/constants';
import { LANG } from '../../../../services/Config';
import { ECONOMY_PAGES } from '../../../../pages/MyEconomy/MyEconomy';
import useIsMyScoopr from '../../../../hooks/IsMyScoopr';
import useIsMobile from '../../../../hooks/IsMobile';
import MenuActionButton from '../MenuActionButton';

import TopBarItem from './_components/TopBarItem';
import useStyles from './TopBar.styles';

export const getActiveTab = (path, items, routes) => {
  const name = path.split('/')?.[1];
  const isMyEconomy = matchPath(path, {
    path: ECONOMY_PAGES.map(i => routes[i.name]),
    exact: false,
  });
  if (isMyEconomy) {
    return items[2].key; // your-economy item
  }
  const foundItem = items.find(item => item.href.split('/')?.[1] === name);
  return foundItem?.key || false; // false returned when all items should be inactive
};

const TopBar = ({ setSideBarOpen, isSideBarOpen, ...rest }) => {
  const classes = useStyles();
  const { isMyScoopr } = useIsMyScoopr();
  const { isMobile } = useIsMobile();

  const { appStrings, routes, economyRoutes } = LANG;
  const { pathname } = useLocation();

  const menuItems = getMenuItems(appStrings, routes);
  const [activeTab, setActiveTab] = useState('refin');

  useEffect(() => {
    const active = getActiveTab(pathname, menuItems, economyRoutes);
    setActiveTab(active);
  }, [pathname]);

  const topBarRef = useRef(null);
  const { search } = useLocation();

  return (
    <AppBar
      className={clsx(
        classes.appBar,
        isMyScoopr && isMobile ? classes.minScoopr : null,
      )}
      position="fixed"
      elevation={20}
      {...rest}
    >
      <Toolbar role="toolbar" className={classes.toolbar} ref={topBarRef}>
        <IconButton
          color="primary"
          onClick={setSideBarOpen}
          className={classes.openNavButton}
          aria-label={
            isSideBarOpen ? appStrings.MENU.CLOSE : appStrings.MENU.OPEN
          }
          size="large"
        >
          {isSideBarOpen ? <CloseXIcon /> : <HamburgerMenuIcon />}
        </IconButton>

        <RouterLink
          aria-label={appStrings.MENU.HOME}
          to={`${routes.HOME}${search}`}
        >
          <img alt="Scoopr logo" src={Logo} className={classes.logo} />
        </RouterLink>

        <div className={classes.menuContainer}>
          <Tabs
            indicatorColor="primary"
            textColor="primary"
            value={activeTab}
            classes={{ root: classes.tabsRoot }}
            TabIndicatorProps={{ classes: { root: classes.menuIndicator } }}
          >
            {menuItems.map(item => (
              <TopBarItem
                ref={topBarRef?.current}
                key={item.key}
                value={item.key}
                menuItem={item}
              />
            ))}
          </Tabs>

          <MenuActionButton className={classes.button} />
        </div>
      </Toolbar>
    </AppBar>
  );
};

TopBar.propTypes = {
  setSideBarOpen: PropTypes.func,
  isSideBarOpen: PropTypes.bool,
};

TopBar.defaultProps = {
  setSideBarOpen: () => {},
  isSideBarOpen: false,
};

export default TopBar;

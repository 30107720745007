import React, { Suspense } from 'react';
import './assets/styles/index.scss';
import CssBaseline from '@mui/material/CssBaseline';
import { BrowserRouter } from 'react-router-dom';
import dayjs from 'dayjs';
import { LazyMotion, domAnimation } from 'framer-motion';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';

import version from '../package.json';

import ScrollToTop from './components/ScrollToTop';
import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import { TrackingProvider } from './services/Tracking/Tracking';
import { BrainSessionProvider } from './services/BrainSession/BrainSession';
import 'dayjs/locale/nb';
import 'moment-timezone';
import Loader from './components/Loader';
import Layout from './components/Layout';
import { AuthProvider } from './services/Auth/Auth';
import { UserStoreProvider } from './pages/MyScoopr/_services/UserStore';
import theme from './assets/styles/theme';

dayjs.locale('nb');

function App() {
  console.log(`Portal version: ${version.version}`);
  return (
    <Suspense fallback={<Loader />}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <BrowserRouter>
            <TrackingProvider>
              <BrainSessionProvider>
                <AuthProvider>
                  <UserStoreProvider>
                    <CssBaseline />
                    <ScrollToTop />
                    <LazyMotion features={domAnimation} strict>
                      <Layout />
                    </LazyMotion>
                  </UserStoreProvider>
                </AuthProvider>
              </BrainSessionProvider>
            </TrackingProvider>
          </BrowserRouter>
        </ThemeProvider>
      </StyledEngineProvider>
    </Suspense>
  );
}

export default App;

import React, { createContext, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import { transformers } from '../../../services/Http';
import { LANG } from '../../../services/Config';

const UserStoreContext = createContext(undefined);

const RISK = [
  { label: 'Svært lav', scoreRange: [0, 20] },
  { label: 'Ganske lav', scoreRange: [21, 29] },
  { label: 'God', scoreRange: [30, 50] },
  { label: 'Ganske god', scoreRange: [51, 70] },
  { label: 'Svært god', scoreRange: [71, 100] },
];

function UserStoreProvider({ children }) {
  const [isUserDataLoading, setIsUserDataLoading] = useState(true);
  const [isDebtCheckLoading, setIsDebtCheckLoading] = useState(true);
  const [isCreditScoreLoading, setIsCreditScoreLoading] = useState(true);
  const [debtData, setDebtData] = useState(null);
  const [debtOffer, setDebtOffer] = useState(null);
  const [scoreData, setScoreData] = useState(null);
  const [scoreStrData, setScoreStrData] = useState(null);
  const [debtStrData, setDebtStrData] = useState(null);
  const [scoreCommandId, setScoreCommandId] = useState(null);
  const [debtCommandId, setDebtCommandId] = useState(null);
  const [creditScoreRating, setCreditScoreRating] = useState(null);
  const [remarks, setRemarks] = useState(null);
  const history = useHistory();
  const { routes } = LANG;

  const checkLoading = () => {
    if (isUserDataLoading) {
      history.push(routes.MY_SCOOPR_DASHBOARD);
    }
  };

  const checkResponseError = err => {
    let error = null;
    if (err) {
      error = transformers.prepareResponseError(err);
      // const {
      //   response: { status },
      // } = error;
      // if (
      //   status &&
      //   (status === 401 || status === 403 || status === 409 || status === 500)
      // ) {
      //   logout();
      //   return true;
      // }
      if (error) {
        return true;
      }
    }
    return false;
  };

  const checkRemarks = remarks => {
    if (remarks) {
      let rem = 0;
      for (const property in remarks) {
        if (
          remarks[property] &&
          property !== 'voluntaryPledgeNumber' &&
          property !== 'voluntaryPledgeAmount'
        ) {
          rem = rem + remarks[property];
        }
      }
      return rem > 0;
    }
    return false;
  };

  const getRiskRange = score => {
    if (!score || score < 0) return RISK[0];
    if (score > 100) return RISK[4];
    return RISK.find(item => {
      if (score >= item.scoreRange[0] && score <= item.scoreRange[1]) {
        return item;
      }
      return null;
    });
  };

  const getCreditScoreData = data => {
    return JSON.parse(data);
  };

  const getAllLoansList = data => {
    if (!data || data?.length === 0) {
      return [];
    }
    const result = [];
    data.forEach(i => {
      let { loans, type } = i;
      if (type === 'creditFacility') {
        result.creditFacility = {
          name: 'Rammekreditt',
          loans: [],
          sumCreditLimit: 0,
          sumInterestBalance: 0,
          sumNonInterestBalance: 0,
          type: 'creditFacility',
        };
      }
      if (type === 'chargeCard') {
        result.chargeCard = {
          name: 'Faktureringskort',
          loans: [],
          sumInterestBalance: 0,
          sumNonInterestBalance: 0,
          type: 'chargeCard',
        };
      }
      if (type === 'repaymentLoan') {
        result.repaymentLoan = {
          name: 'Nedbetalingslån',
          loans: [],
          sumOriginal: 0,
          sumInterestBalance: 0,
          type: 'repaymentLoan',
          balance: 0,
        };
      }

      loans = loans.map(l => {
        const loan = l;
        if (loan.interestBearingBalance) {
          result[type].sumInterestBalance += Number(
            loan.interestBearingBalance,
          );
        }
        if (loan.creditLimit) {
          result[type].sumCreditLimit += Number(loan.creditLimit);
        }
        if (loan.originalBalance) {
          result[type].sumOriginal += Number(loan.originalBalance);
        }
        if (loan.nonInterestBearingBalance) {
          result[type].sumNonInterestBalance += Number(
            loan.nonInterestBearingBalance,
          );
        }
        if (loan.balance) {
          result[type].balance += Number(loan.balance);
        }
        return loan;
      });

      result[type].loans.push(...loans);
    });
    return result;
  };

  const calcUnsecuredSum = loans => {
    let sum = 0;
    if (loans) {
      if (loans.chargeCard) {
        sum +=
          (loans.chargeCard?.sumInterestBalance || 0) +
          (loans.chargeCard?.sumNonInterestBalance || 0);
      }
      if (loans.repaymentLoan) {
        sum += loans.repaymentLoan?.balance || 0;
      }
      if (loans.creditFacility) {
        sum +=
          (loans.creditFacility?.sumInterestBalance || 0) +
          (loans.creditFacility?.sumNonInterestBalance || 0);
      }
    }
    return sum;
  };

  const value = {
    checkResponseError,
    getRiskRange,
    getAllLoansList,
    calcUnsecuredSum,
    checkRemarks,
    checkLoading,
    getCreditScoreData,
    creditScoreRating,
    setCreditScoreRating,
    remarks,
    setRemarks,
    isUserDataLoading,
    debtData,
    setDebtData,
    debtOffer,
    setDebtOffer,
    setIsDebtCheckLoading,
    setIsCreditScoreLoading,
    isCreditScoreLoading,
    isDebtCheckLoading,
    setIsUserDataLoading,
    scoreData,
    setScoreData,
    scoreCommandId,
    setScoreCommandId,
    debtCommandId,
    setDebtCommandId,
    scoreStrData,
    setScoreStrData,
    debtStrData,
    setDebtStrData,
  };

  return (
    <UserStoreContext.Provider value={value}>
      {children}
    </UserStoreContext.Provider>
  );
}

UserStoreProvider.propTypes = {
  children: PropTypes.node,
};

UserStoreProvider.defaultProps = {
  children: null,
};

export { UserStoreContext, UserStoreProvider };

import makeStyles from '@mui/styles/makeStyles';

export default makeStyles(({ breakpoints, spacing, typography }) => ({
  tabContainer: {
    margin: spacing(0, 1.5),
    [breakpoints.up(1100)]: {
      margin: spacing(0, 3.5),
    },
  },
  tabRoot: {
    minWidth: 0,
    ...typography.p3SemiBold,
    padding: spacing(3.75, 0, 2.75),
    '&:hover': {
      opacity: 0.7,
    },
  },
}));

import makeStyles from '@mui/styles/makeStyles';

export default makeStyles(({ breakpoints, palette, spacing }) => ({
  text: {
    textAlign: 'center',
    '&$default': { color: palette.text.default },
    '&$contrast': { color: palette.text.contrast },
    margin: spacing(0, 1, 4),
    [breakpoints.up('sm')]: {
      margin: spacing(0, 2, 6),
    },
    [breakpoints.up('xl')]: {
      margin: spacing(0, 2, 8),
    },
  },
  withSubtitle: {
    margin: spacing(0, 2, 2.5),
    [breakpoints.up('sm')]: {
      margin: spacing(0, 2, 2.5),
    },
    [breakpoints.up('xl')]: {
      margin: spacing(0, 2, 2.5),
    },
  },
  default: {},
  contrast: {},
}));

import React from 'react';
import { SvgIcon } from '@mui/material';
import PropTypes from 'prop-types';

const CircleIcon = ({ color, strokeWidth, ...props }) => (
  <SvgIcon
    width="50"
    height="50"
    viewBox="0 0 50 50"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M47 25C47 37.1503 37.1503 47 25 47C12.8497 47 3 37.1503 3 25C3 12.8497 12.8497 3 25 3"
      stroke={color}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
    />
  </SvgIcon>
);

CircleIcon.propTypes = {
  color: PropTypes.string,
  strokeWidth: PropTypes.number,
};

CircleIcon.defaultProps = {
  color: '#18A0FB',
  strokeWidth: 2,
};
export default CircleIcon;

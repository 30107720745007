import React from 'react';

import { AuthContext } from './Auth';

export default function useAuth() {
  const context = React.useContext(AuthContext);

  if (context === undefined) {
    throw new Error('useLang must be used within an AuthProvider');
  }

  return context;
}

import { createTheme, adaptV4Theme } from '@mui/material/styles';

import shadows from './shadows';
import typography from './typography';

export const palette = {
  primary: {
    main: '#18a0fb',
    medium: '#74C6FD',
    light: '#D1ECFE',
  },
  common: {
    white: '#FFF',
    black: '#000',
  },
  secondary: {
    main: '#FDC564',
    medium: '#FFDFA2',
    light: '#FFF4E0',
  },
  accent: {
    main: '#FA4969',
  },
  background: {
    default: '#EFF7FF',
    contrast: '#031839',
    light: '#CEE2FF',
    primary: '#18a0fb',
    disabled: '#aaaaaa',
    lightest: '#FBFDFF',
  },
  neutral: {
    dark: '#354661',
    medium: '#687488',
    medium2: '#9AA3B0',
    light: '#CDD1D7',
  },
  text: {
    default: '#031839',
    contrast: '#FFFFFF',
    primary: '#18a0fb',
  },
  chat: {
    bubble: {
      user: {
        background: '#FFFFFF',
        text: '#031839',
        border: '#FDC564',
      },
      scoopr: {
        background: '#FFFFFF',
        text: '#031839',
      },
      loading: {
        default: '#18a0fb',
      },
    },
    button: {
      default: {
        background: '#FDC564',
        text: '#031839',
      },
      transparent: {
        border: '#031839',
        text: '#031839',
      },
    },
    input: {
      border: '#18A0FB',
    },
  },
  success: {
    main: '#56BA82',
  },
  error: {
    main: '#FA4969',
  },
};

const { palette: defaultPalette, typography: defaultTypography } =
  createTheme();
// A custom theme for this app
const theme = createTheme({
  palette: { ...defaultPalette, ...palette },
  shadows,
  typography: { ...defaultTypography, ...typography },
  components: {
    MuiButtonBase: {
      disableRipple: true,
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 50,
          textTransform: 'none',
          color: 'inherit',
        },
        sizeSmall: {
          ...typography.button3,
          padding: '5px 18px',
          '@media (min-width:600px)': {
            ...typography.button3,
            padding: '8px 18px',
          },
        },
        sizeLarge: {
          ...typography.button1,
          padding: '10px 36px',
          '@media (min-width:600px)': {
            ...typography.button1,
            padding: '15px 36px',
          },
        },
        contained: {
          ...typography.button2,
          padding: '7px 24px',
          '@media (min-width:600px)': {
            ...typography.button2,
            padding: '11px 24px',
          },

          color: palette.text.default,
          border: `2px solid ${palette.common.white}`,
          boxShadow: 'none',
          '&:hover': {
            border: `2px solid ${palette.background.contrast}`,
            color: palette.text.contrast,
            backgroundColor: palette.background.contrast,
            boxShadow: 'none',
          },
        },
        outlined: {
          ...typography.button2,
          padding: '7px 24px',
          '@media (min-width:600px)': {
            ...typography.button2,
            padding: '11px 24px',
          },
        },
        text: {
          '&:hover': {
            backgroundColor: 'transparent',
            textDecoration: 'underline',
          },
        },
        containedPrimary: {
          border: `2px solid ${palette.primary.main}`,
          color: palette.common.white,
          '&:hover': {
            border: `2px solid ${palette.primary.main}`,
            color: palette.text.default,
            backgroundColor: palette.common.white,
          },
        },
        outlinedPrimary: {
          color: palette.text.default,
          border: `2px solid ${palette.primary.main}`,
          backgroundColor: palette.common.white,
          '&:hover': {
            color: palette.text.contrast,
            border: `2px solid ${palette.primary.main}`,
            backgroundColor: palette.primary.main,
          },
        },
        containedSecondary: {
          border: `2px solid ${palette.secondary.main}`,
          color: palette.text.default,
          '&:hover': {
            border: `2px solid ${palette.secondary.main}`,
            color: palette.text.default,
            backgroundColor: palette.common.white,
          },
          '&$disabled': {
            border: `2px solid ${palette.secondary.medium}`,
            color: palette.text.contrast,
            backgroundColor: palette.secondary.medium,
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          color: 'inherit',
          padding: 8,
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          fontWeight: typography.fontWeightSemiBold,
        },
        underlineNone: {
          textDecoration: 'none',
        },
        underlineHover: {
          fontSize: '1rem',
          '@media (min-width:600px)': {
            fontSize: '1.125rem',
          },
        },
      },
    },
    MuiBreadcrumbs: {
      styleOverrides: {
        li: {
          ...typography.p1SemiBold,
          '&:last-child > *': {
            color: palette.text.default,
          },
        },
        separator: {
          ...typography.p1SemiBold,
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        head: {
          color: palette.text.default,
          fontWeight: typography.fontWeightBold,
        },
        body: {
          color: palette.text.default,
        },
        root: {
          fontSize: '1rem',
          padding: 8,
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          verticalAlign: 'top',
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        wrapper: {
          flexDirection: 'row-reverse',
        },
        labelIcon: {
          paddingTop: 28,
        },
        root: {
          textTransform: 'none',
          fontWeight: typography.fontWeightBold,
        },
        textColorPrimary: {
          color: palette.text.default,
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          fontFamily: "'Nunito', sans-serif",
          color: palette.text.default,
        },
        '@global': {
          body: {
            fontFamily: "'Nunito', sans-serif",
            color: palette.text.default,
          },
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: '#fff',
        },
        colorPrimary: {
          backgroundColor: '#fff',
        },
      },
    },
  },
});
export default theme;

import React, { createContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import jwt_decode from 'jwt-decode';
import { Base64 } from 'js-base64';
import Cookies from 'js-cookie';

import appStringsNo from '../../assets/constants/appStrings-no';
import routesNo from '../../assets/constants/routes-no';
import { transformers } from '../Http';
import { API, DOMAIN } from '../Config';
import useLocalStorage from '../../hooks/LocalStorage';
import useBrainSession from '../BrainSession/useBrainSession';
import { useQueryParams } from '../Utils';
import useTracking from '../Tracking/useTracking';
import { AuthHttp } from '../Http/urls/Auth.http';

const AuthContext = createContext(undefined);

function AuthProvider({ children }) {
  const local = false;
  const paramToken = useQueryParams('token');
  const [loading, setLoading] = useState(true);
  const sid = useQueryParams('sid');
  const [authData, setAuthData] = useState({
    isAuthInit: false,
    isConsentsInit: false,
    isAuth: false,
    haveConsents: false,
    email: null,
  });
  const [token, setToken] = useLocalStorage(DOMAIN.token, null);
  const { brainSessionId, userId } = useBrainSession();
  const { addEvent } = useTracking();

  const getAuthToken = async extSession => {
    try {
      const res = await AuthHttp.post.authToken(brainSessionId?.id, extSession);
      if (res.status === 200) {
        const { access: accessToken, refresh: refreshToken } = res.data;
        setToken({ accessToken, refreshToken });
        return { accessToken, refreshToken };
      }
    } catch (ex) {
      const errorContent = transformers.prepareResponseError(ex);
      console.log('ex', errorContent);
      setLoading(false);
      return null;
    }
  };

  const removeLocalStorage = () => {
    localStorage.removeItem('SCOOPR_BRAIN_SESSION_ID');
    localStorage.removeItem('SCOOPR_REGISTER_SOURCE');
    localStorage.removeItem('SCOOPR_TOKEN');
    localStorage.removeItem('SCOOPR_USER_ID');
  };

  const logout = async () => {
    removeLocalStorage();
    try {
      await AuthHttp.post.accountLogout(token.accessToken, token.refreshToken);
    } catch (ex) {
      const errorContent = transformers.prepareResponseError(ex);
      console.log('ex', errorContent);
    }
    addEvent('userLogout');
    local
      ? window.location.reload(true)
      : (window.location.href = `${API.url}/account/logout/?redirect_url=${API.domain}/min-scoopr/login`);
  };

  const getTokenFromCookies = () => {
    const tokenRefreshCookie = Cookies.get('SCOOPR_JWT_TOKEN_REFRESH') || '';
    const tokenAccessCookie = Cookies.get('SCOOPR_JWT_TOKEN_ACCESS') || '';
    let parsedTokenCookie = null;

    if (tokenRefreshCookie !== '' && tokenAccessCookie !== '') {
      parsedTokenCookie = {
        accessToken: tokenAccessCookie,
        refreshToken: tokenRefreshCookie,
      };
    }
    return parsedTokenCookie;
  };

  const getTokenFromParam = () => {
    let tokenFromParam = null;
    if (paramToken) {
      const decodedToken = Base64.decode(paramToken);
      if (decodedToken && JSON.parse(decodedToken).refresh) {
        const { refresh, access } = JSON.parse(decodedToken);
        tokenFromParam = { accessToken: access, refreshToken: refresh };
      }
    }
    return tokenFromParam;
  };

  // check if refresh token exists and if is it valid
  useEffect(async () => {
    if (sid) {
      setToken(null);
    }
    if (!authData.isAuthInit) {
      let t;
      const setup = {
        ...authData,
      };
      const tokenFromParam = getTokenFromParam();
      const tokenFromCookies = getTokenFromCookies();
      if (tokenFromParam || tokenFromCookies) {
        t = tokenFromParam ? tokenFromParam : tokenFromCookies;
      } else if (sid) {
        t = null;
      } else {
        t = token;
      }
      if (t) {
        const now = Math.ceil(Date.now() / 1000);
        const { accessToken, refreshToken } = t;
        if (refreshToken) {
          const rtDecoded = jwt_decode(refreshToken) || null;
          if (rtDecoded.exp < now) {
            t = null;
          }
        }
        if (accessToken && t) {
          const atDecoded = jwt_decode(accessToken) || null;
          if (atDecoded?.is_anonymous === false) {
            setup.isAuth = true;
          }
        }
        setToken(t);
      }
      if (setup.isAuth) {
        try {
          const { data } = await AuthHttp.get.accountMe();
          if (data && data.email) {
            setup.email = data.email;
          }
        } catch (ex) {
          const errorContent = transformers.prepareResponseError(ex);
          console.log('ex', errorContent);
          setToken(null);
          removeLocalStorage();
          window.location.reload();
        }
        // setup.haveConsents = await fetchConsents([
        //   'cc-scoopr-marketing-summary',
        //   'cc-scoopr-account-summary',
        //   'cc-scoopr-debt_register_api-summary',
        //   'cc-scoopr-creditsafe-right_of_access-summary',
        // ]);
      }
      setup.isAuthInit = true;
      setup.isConsentsInit = true;
      setAuthData(setup);
    }
  }, []);

  const acceptConsents = async (consents, accept) => {
    if (!authData.haveConsents && token) {
      try {
        const res = await AuthHttp.patch.consentClusterApplyConsents(consents);
        if (res?.status === 204) {
          if (accept) {
            setAuthData(prev => ({ ...prev, haveConsents: true }));
          }
        }
      } catch (ex) {
        const errorContent = transformers.prepareResponseError(ex);
        console.log('ex', errorContent);
      }
    }
  };

  const checkMe = async () => {
    const { data } = await AuthHttp.get.accountMe();
    return data;
  };

  const saveEmail = async e => {
    return await AuthHttp.patch.accountMe(e);
  };

  const getConsents = async () => {
    const { data } = await AuthHttp.get.consent(50);
    return data;
  };

  const deleteUser = async () => {
    try {
      await AuthHttp.delete.accountDelete();
      addEvent('userDeleted', { user_id: userId });
      await logout();
    } catch (ex) {
      const errorContent = transformers.prepareResponseError(ex);
      console.log('ex', errorContent);
    }
  };

  const login = async () => {
    try {
      const res = await AuthHttp.get.accountLogin(local);
      if (res?.data?.url) {
        window.location.href = res?.data?.url;
      }
    } catch (ex) {
      const errorContent = transformers.prepareResponseError(ex);
      await logout();
      console.log('ex', errorContent);
    }
  };

  // const fetchConsents = async consents => {
  //   try {
  //     const res = await AuthHttp.post.consentsCheckAll([...consents]);
  //     console.log('res.data', res.data);
  //     const { data } = res;
  //     return !(
  //       !data['cc-scoopr-account-summary'] ||
  //       !data['cc-scoopr-debt_register_api-summary'] ||
  //       !data['cc-scoopr-creditsafe-right_of_access-summary']
  //     );
  //   } catch (ex) {
  //     const errorContent = transformers.prepareResponseError(ex);
  //     console.log('ex', errorContent);
  //   }
  // };

  const authInit = async () => {
    await getAuthToken();
  };

  const value = {
    acceptConsents,
    getAuthToken,
    authInit,
    login,
    logout,
    checkMe,
    deleteUser,
    getConsents,
    saveEmail,
    setAuthData,
    loading,
    token,
    authData,
    appStrings: appStringsNo,
    routes: routesNo,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

AuthProvider.propTypes = {
  children: PropTypes.node,
};

AuthProvider.defaultProps = {
  children: null,
};

export { AuthContext, AuthProvider };

import makeStyles from '@mui/styles/makeStyles';

export default makeStyles(({ palette }) => ({
  chatWrapper: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: 9999,
    backgroundColor: palette.background.default,
    overflowX: 'hidden',
  },
}));

import makeStyles from '@mui/styles/makeStyles';

import { SIZES } from '../../../../assets/constants/constants';

export default makeStyles(({ breakpoints, spacing }) => ({
  drawerPaper: {
    marginTop: SIZES.topBar.height.small,
    width: '100%',
  },
  drawer: {
    [breakpoints.up(SIZES.navigationChange)]: {
      display: 'none',
    },
  },
  drawerContent: {
    height: `calc(100vh - ${SIZES.topBar.height.small}px)`,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    paddingTop: spacing(3),
    overflowY: 'auto',
  },
  buttons: {
    display: 'flex',
    flexDirection: 'column',
    padding: spacing(3, 8, 8),
    '& > a': {
      marginTop: spacing(2),
    },
    [breakpoints.down(450)]: {
      padding: spacing(3, 6, 8),
      '& > button, a': {
        paddingLeft: spacing(1.5),
        paddingRight: spacing(1.5),
      },
    },
  },
}));

import React from 'react';
import { SvgIcon } from '@mui/material';

const MyDebtIcon = ({ ...props }) => (
  <SvgIcon
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_1162_6405)">
      <path
        d="M18.375 5H6.375C4.71815 5 3.375 6.34315 3.375 8V16C3.375 17.6569 4.71815 19 6.375 19H18.375C20.0319 19 21.375 17.6569 21.375 16V8C21.375 6.34315 20.0319 5 18.375 5Z"
        stroke="#9AA3B0"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.375 10H21.375"
        stroke="#9AA3B0"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.375 15H7.385"
        stroke="#9AA3B0"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.375 15H13.375"
        stroke="#9AA3B0"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_1162_6405">
        <rect
          width="24"
          height="24"
          fill="white"
          transform="translate(0.375)"
        />
      </clipPath>
    </defs>
  </SvgIcon>
);

export default MyDebtIcon;

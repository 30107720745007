import jwtDecode from 'jwt-decode';
import currencyFormatter from 'currency-formatter';
import { useLocation } from 'react-router-dom';
import { lazy } from 'react';

import { DOMAIN } from './Config';

function getUserAgent() {
  return window.navigator.userAgent || null;
}

function isTokenValid(token) {
  if (token) {
    const decodedJwt = jwtDecode(token);
    return !(
      decodedJwt && new Date(Date.now()) >= new Date(decodedJwt.exp * 1000)
    );
  }
  return false;
}

function getEmailFromToken(token) {
  if (token) {
    const decodedJwt = jwtDecode(token);
    if (decodedJwt && decodedJwt.email) {
      return decodedJwt.email;
    }
    return 'test@email.no';
  }
}

const formatPrice = (sum, precision, symbolPlace) => {
  let format;
  const config = {
    left: {
      pos: '%s %v', // %s - symbol, %v - value
      neg: '%s -%v',
      zero: '%s %v',
    },
    right: {
      pos: '%v %s', // %v - value, %s - symbol
      neg: '-%v %s',
      zero: '%v %s',
    },
  };
  if (symbolPlace === 'right') {
    format = { ...config.right };
  } else {
    format = { ...config.left };
  }
  if (!sum && !symbolPlace) return 'kr 0';
  if (!sum && symbolPlace) return '0 kr';
  return currencyFormatter.format(sum, {
    locale: 'nb-NO',
    format: {
      ...format,
    },
    precision, // numbers after comma
  });
};

function useQueryParams(param) {
  const query = new URLSearchParams(useLocation().search);
  return query.get(param);
}

function getTokenFromLS() {
  const token = JSON.parse(localStorage.getItem(DOMAIN.token));
  return token || null;
}

function removeTokenFromLS() {
  localStorage.removeItem(DOMAIN.token);
}

function setAccessTokenToLS(accessToken) {
  let token = JSON.parse(localStorage.getItem(DOMAIN.token)) || null;
  if (accessToken) {
    if (token && token.accessToken && token.refreshToken) {
      token.accessToken = accessToken;
      localStorage.setItem(DOMAIN.token, JSON.stringify(token));
    } else {
      token = null;
    }
  }
  return token;
}

function lazyComponentLoader(lazyComponent, attemptsLeft) {
  return new Promise((resolve, reject) => {
    lazyComponent()
      .then(resolve)
      .catch(error => {
        setTimeout(() => {
          if (attemptsLeft === 1) {
            reject(error);
            return;
          }
          lazyComponentLoader(lazyComponent, attemptsLeft - 1).then(
            resolve,
            reject,
          );
        }, 500);
      });
  });
}

function inputValidation(
  value,
  lib,
  numberMax = null,
  numberMin = null,
  email = null,
  phoneNumber = null,
) {
  let err = null;
  if (value) {
    if (numberMax || numberMin) {
      return validateMinMaxNumberInput(value, numberMax, numberMin, lib);
    }
    if (email) {
      return validateEmailInput(value, email, lib);
    }
    if (phoneNumber) {
      return validatePhoneNumberInput(value, phoneNumber, lib);
    }
  }
  return err;
}

function validateEmailInput(value, email = null, lib) {
  let err = null;
  if (
    String(value)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      )
  ) {
    return err;
  } else {
    let t = lib ? lib.email : `Provide correct email address`;
    return {
      isError: true,
      text: t,
    };
  }
}

function validatePhoneNumberInput(value, phoneNumber = null, lib) {
  let err = null;
  if (value && value.length !== 8) {
    let t = lib
      ? lib.phone_number
      : `Expected to get 8 digit long phone number`;
    err = {
      isError: true,
      text: t,
    };
  }
  return err;
}

function validateMinMaxNumberInput(
  value,
  numberMax = null,
  numberMin = null,
  lib = null,
) {
  let err = null;
  const numberValue = Number(value);
  if (
    numberMax !== null &&
    numberMin !== null &&
    (numberValue < numberMin || numberValue > numberMax)
  ) {
    let t = lib
      ? lib.range
      : `Value should be between ${formatPrice(
          numberMin,
          0,
          'right',
        )} and ${formatPrice(numberMax, 0, 'right')}`;
    err = {
      isError: true,
      text: t,
    };
  }
  if (
    numberMax !== null &&
    numberMin !== null &&
    numberValue >= numberMin &&
    numberValue <= numberMax
  ) {
    err = null;
  }
  if (numberMax !== null && numberMin === null && numberValue > numberMax) {
    let t = lib
      ? lib.max
      : `Value should be greater than ${formatPrice(numberMax, 0, 'right')}`;
    err = {
      isError: true,
      text: t,
    };
  }
  if (numberMax !== null && numberMin === null && numberValue <= numberMax) {
    err = null;
  }
  if (numberMax === null && numberMin !== null && numberValue < numberMin) {
    let t = lib
      ? lib.min
      : `Value cannot be lower than ${formatPrice(numberMin, 0, 'right')}`;
    err = {
      isError: true,
      text: t,
    };
  }
  if (numberMax === null && numberMin !== null && numberValue >= numberMin) {
    err = null;
  }
  return err;
}

const lazyWithRetry = componentImport =>
  lazy(async () => {
    const pageHasAlreadyBeenForceRefreshed = JSON.parse(
      window.localStorage.getItem('page-has-been-force-refreshed') || 'false',
    );

    try {
      const component = await componentImport();

      window.localStorage.setItem('page-has-been-force-refreshed', 'false');

      return component;
    } catch (error) {
      if (!pageHasAlreadyBeenForceRefreshed) {
        window.localStorage.setItem('page-has-been-force-refreshed', 'true');
        return window.location.reload();
      }
      throw error;
    }
  });

function getDaysCountBetween(start, last) {
  const date1 = new Date(start);
  const date2 = new Date(last);
  const oneDay = 1000 * 60 * 60 * 24;
  const diffTime = date2.getTime() - date1.getTime();
  return Math.round(diffTime / oneDay);
}

export {
  getUserAgent,
  isTokenValid,
  formatPrice,
  lazyComponentLoader,
  useQueryParams,
  lazyWithRetry,
  getEmailFromToken,
  getDaysCountBetween,
  getTokenFromLS,
  setAccessTokenToLS,
  removeTokenFromLS,
  validateMinMaxNumberInput,
  inputValidation,
};

import React from 'react';
import { Box, CircularProgress } from '@mui/material';
import PropTypes from 'prop-types';

import { LANG } from '../../services/Config';
import Typography from '../Typography';

import useStyles from './Loader.styles';

const Loader = ({ inline, colored, text, size }) => {
  const classes = useStyles({ inline });
  const { appStrings } = LANG;

  return (
    <Box
      alignItems="center"
      display="flex"
      justifyContent="center"
      flex={1}
      mb={inline ? 0 : 8}
      className={text ? classes.flowDirection : classes.loaderContainer}
    >
      {text ? (
        <Typography variant="p3" color="primary" className={classes.loaderText}>
          {text}
        </Typography>
      ) : null}
      <CircularProgress
        size={size}
        color={colored ? 'secondary' : 'primary'}
        aria-label={appStrings.LOADING}
      />
    </Box>
  );
};

Loader.propTypes = {
  inline: PropTypes.bool,
  colored: PropTypes.bool,
  text: PropTypes.string,
  size: PropTypes.any,
};

Loader.defaultProps = {
  inline: false,
  colored: false,
  text: null,
  size: 30,
};

export default Loader;

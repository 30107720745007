import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { BottomNavigation, BottomNavigationAction, Box } from '@mui/material';

import { LANG } from '../../../../services/Config';
import HomeIcon from '../../../../assets/icons/MinScooprMenu/HomeIcon';
import MySavingsIcon from '../../../../assets/icons/MinScooprMenu/MySavingsIcon';
import useAuth from '../../../../services/Auth/useAuth';
import useUserStore from '../../../../pages/MyScoopr/_services/useUserStore';
import MyDebtIcon from '../../../../assets/icons/MinScooprMenu/MyDebtIcon';
import MyScoreIcon from '../../../../assets/icons/MinScooprMenu/MyScoreIcon';

import useStyles from './MinScooprMenu.styles';

const MinScooprMenu = () => {
  const history = useHistory();
  const { pathname } = useLocation();
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const { authData } = useAuth();
  const { isUserDataLoading } = useUserStore();
  const { routes } = LANG;

  useEffect(() => {
    const s = pathname.split('/');
    if (s && s.length === 3) {
      if (s[2] === 'dashboard') {
        setValue(0);
      } else if (s[2] === 'mitt-sparepotensiale') {
        setValue(1);
      } else if (s[2] === 'min-gjeld') {
        setValue(2);
      } else if (s[2] === 'min-kredittscore') {
        setValue(3);
      } else {
        setValue(5);
      }
    }
  }, [pathname]);

  const routing = [
    routes.MY_SCOOPR_DASHBOARD,
    routes.MY_SCOOPR_SAVINGS_POTENTIAL,
    routes.MY_SCOOPR_MY_DEBT,
    routes.MY_SCOOPR_MY_CREDIT_SCORE,
  ];

  return authData.haveConsents && authData.isAuth && !isUserDataLoading ? (
    <Box sx={{ width: 477 }}>
      <BottomNavigation
        showLabels
        value={value}
        onChange={(event, newValue) => {
          history.push(routing[newValue]);
        }}
        className={classes.stickBottom}
      >
        <BottomNavigationAction label="Oversikt" icon={<HomeIcon />} />
        <BottomNavigationAction
          label="Sparepotensiale"
          icon={<MySavingsIcon />}
        />
        <BottomNavigationAction label="Min Gjeld" icon={<MyDebtIcon />} />
        <BottomNavigationAction
          label="Min kredittscore"
          icon={<MyScoreIcon />}
        />
      </BottomNavigation>
    </Box>
  ) : null;
};

MinScooprMenu.propTypes = {};

export default MinScooprMenu;

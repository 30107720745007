import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import trim from 'lodash/trim';
import takeRight from 'lodash/takeRight';
import isNumber from 'lodash/isNumber';
import { Container } from '@mui/material';
import { AnimatePresence, m as lazyMotion } from 'framer-motion';

import useBrainSession from '../../services/BrainSession/useBrainSession';
import useTracking from '../../services/Tracking/useTracking';
import Http from '../../services/Http/Http';
import { API, LANG_CODE, LANG } from '../../services/Config';
import { useQueryParams, formatPrice } from '../../services/Utils';
import CircularLoader from '../CircularLoader';

import ChatBubbles from './_components/ChatBubbles';
import ChatAnswers from './_components/ChatAnswers';
import ChatHeader from './_components/ChatHeader';
import useStyles from './Chat.styles';
const { routes } = LANG;

const loaderVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      damping: 2,
      ease: 'easeInOut',
      velocity: -100,
    },
  },
  exit: {
    opacity: 0,
    transition: {
      damping: 2,
      ease: 'easeInOut',
      velocity: -100,
    },
  },
};

const Chat = ({ goal, step, clientKnowledge, firstMessage }) => {
  const classes = useStyles();
  const {
    brainSessionId,
    closeChatModal,
    setBrainSessionId,
    saveCloseModalUrl,
    closeModalUrl,
    rParams,
    setRParams,
    userId,
    getVertex,
  } = useBrainSession();
  const { wsSendMessage, wsLastMessage, wsReadyState, addValue } =
    useTracking();
  const { initFacebookPixel } = useTracking();
  const insuranceAPPID = useQueryParams('iappid');
  const sessionBrainGoalId = useQueryParams('sbgid');
  const goalFlowSlug = useQueryParams('gfslug');
  const [messages, setMessages] = useState(() => {
    const arr = [];
    if (firstMessage) {
      arr.push({
        ...firstMessage,
        resulting_state: 'STEPPING',
      });
    }
    return arr;
  });
  const history = useHistory();
  const location = useLocation();
  const [lastMessage, setLastMessage] = useState(null);
  const [progress, setProgress] = useState(null);
  const [initBobLoading, setInitBobLoading] = useState(true);
  const [initWs, setInitWs] = useState(false);
  const [backButton, setBackButton] = useState(false);
  const [animation, setAnimation] = useState(); // intro or outro
  const [wfrAnimation, setWfrAnimation] = useState(); // wait for response animation
  const [closeUrl] = useState(closeModalUrl);

  const getLastMessage = () => {
    if (messages && messages.length) {
      return takeRight(messages);
    }
    return null;
  };

  const handleUrl = str => {
    let s = str;
    if (str && str.length) {
      if (str[str.length - 1] === '/') {
        s = s.slice(0, -1);
      }
    }
    const url = new URL(s);
    url.searchParams.set('session', brainSessionId.id);
    return url;
  };

  const convertString = s => {
    if (s) {
      return trim(s);
    }
    return '';
  };

  const handleBobInit = async () => {
    const queryContent = {
      language_code: LANG_CODE.nb,
      action: 'activate',
      goal_flow: goal || '',
      step: step || '',
      query_params: {},
    };
    if (insuranceAPPID) {
      queryContent.query_params.iappid = insuranceAPPID;
    }
    if (sessionBrainGoalId) {
      queryContent.query_params.sbgid = sessionBrainGoalId;
    }
    if (goalFlowSlug) {
      queryContent.query_params.gfslug = goalFlowSlug;
    }
    try {
      const ver = getVertex(brainSessionId, clientKnowledge);
      const res = await Http.post(API.bobUri, {
        vertex: {
          ...ver,
        },
        content_type: 'goal',
        content: queryContent,
        contexts: [],
      });
      const { data } = res;
      const { id } = data.vertex;
      setBrainSessionId(id);
      const msg = [...messages];
      const { content } = data;
      if (content && content.length) {
        content.forEach(m => {
          msg.push({ ...m, user: false });
          const stepParams = m.parameters || {};
          if (stepParams.fb_event) {
            addValue({
              category: 'facebook',
              event: stepParams.fb_event,
            });
          }
          if (stepParams.load_fe_event) {
            addValue({
              event: stepParams.load_fe_event,
            });
          }
        });
      }
      saveCloseModalUrl(null);
      setMessages(msg);
      setInitBobLoading(false);
      setWfrAnimation(false);
    } catch (e) {
      console.log(e);
      setInitBobLoading(false);
    }
  };

  const handleBobTalk = async (message, auto) => {
    let msg = [...messages];
    const lastParameters = lastMessage?.parameters || {};
    if (!auto) {
      let transform = null;
      if (
        lastParameters &&
        lastParameters?.input_format === 'currency' &&
        message?.utterance
      ) {
        const num = Number(message?.utterance);
        if (isNumber(num)) {
          transform = formatPrice(num, 0, 'right');
        }
      }
      msg.push({
        utterance: transform ? transform : message?.utterance,
        user: true,
        resulting_state: 'STEPPING',
      });
      setMessages(msg);
    }

    // display outro animation
    const outroAnimation = lastMessage?.extra_parameters?.outro_animation
      ? {
          text: lastMessage.extra_parameters?.oa_text,
          title: lastMessage.extra_parameters?.oa_title || null,
          description: lastMessage.extra_parameters?.oa_description || null,
          duration: lastMessage.extra_parameters?.oa_duration,
        }
      : undefined;
    if (outroAnimation) {
      setAnimation(outroAnimation);
    }

    if (lastMessage?.extra_parameters?.wait_for_response) {
      setWfrAnimation({
        text: lastMessage?.extra_parameters?.wfr_text || null,
        title: lastMessage?.extra_parameters?.wfr_title || null,
        description: lastMessage?.extra_parameters?.wfr_description || null,
      });
    }
    if (lastParameters.post_fe_event) {
      addValue({
        event: lastParameters.post_fe_event,
      });
    }

    setLastMessage(null); // clear last message to hide suggested responses

    const ver = getVertex(brainSessionId, {});
    try {
      const postData = {
        vertex: {
          ...ver,
        },
        content_type: 'utterance',
        content: {
          language_code: LANG_CODE.nb,
          utterance: convertString(message?.utterance),
          utterance_type: 'text',
        },
      };
      if (message?.payload) {
        postData.content.payload = message.payload;
      }
      if (message?.backAction) {
        postData.content_type = 'goal';
        postData.content = {
          language_code: LANG_CODE.nb,
          action: message.backAction,
        };
      }
      const res = await Http.post(API.bobUri, postData);
      setWfrAnimation(false);
      const { data } = res;
      const { id } = data.vertex;
      setBrainSessionId(id);
      const { uid } = id;
      if (uid) {
        setRParams({
          ...rParams,
          entity_id: uid,
        });
      }
      const { content } = data;
      if (content && content.length) {
        msg = [];
        content.forEach(m => {
          if (m?.content_type !== 'info' || m?.input_type === 'redirect') {
            msg.push({ ...m, user: false });
          }
          const stepParams = m.parameters || {};
          if (stepParams.fb_event) {
            addValue({
              category: 'facebook',
              event: stepParams.fb_event,
            });
          }
          if (stepParams.load_fe_event) {
            addValue({
              event: stepParams.load_fe_event,
            });
          }
        });
      }

      // hide previous messages
      setTimeout(() => {
        setMessages([]);
      }, 500);

      // hide outro animation
      if (outroAnimation) {
        setTimeout(() => setAnimation(undefined), outroAnimation.duration);
      }

      // display newly downloaded messages
      setTimeout(() => {
        if (msg.length > 0 || auto) {
          setMessages(new Array(...msg));
        }
      }, lastMessage?.extra_parameters?.oa_duration ?? 1000);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (wsReadyState === 1 && !initWs && brainSessionId?.id) {
      wsSendMessage(`42["init",{"foreignId":"${brainSessionId.id}"}]`);
      setInitWs(true);
    }
  }, [wsReadyState, brainSessionId]);

  useEffect(() => {
    if (wsLastMessage.type === 'data message') {
      wsLastMessage.payload.user = false;
      if (wsLastMessage.payload.content_type === 'forced-payload') {
        handleBobTalk(wsLastMessage.payload, true);
        return;
      }
      if (
        wsLastMessage.payload?.utterance ===
        'Vi mottar nå din data fra gjeldsregisteret...'
      ) {
        setMessages([wsLastMessage.payload]);
      } else {
        setMessages([...messages, wsLastMessage.payload]);
      }
    }
  }, [wsLastMessage]);

  useEffect(() => {
    const fetchData = async () => {
      await handleBobInit();
    };
    fetchData();
    initFacebookPixel(userId);
  }, []);

  useEffect(() => {
    let m = getLastMessage();
    if (!m) {
      return;
    }
    m = { ...m[0] };
    if (!m.user && !m.resulting_state) {
      return;
    }
    if (!m.user) {
      const lastMsg = {
        state: m.resulting_state,
        answerType: m.suggested_responses?.length
          ? 'defaultButtons'
          : 'defaultInput',
        init: null,
        terminate: null,
        stepId: m?.step?.id || null,
        stepFlow: m?.step?.goal_flow,
        suggestedResponses: m.suggested_responses?.length
          ? m.suggested_responses
          : [],
        parameters: m.parameters,
        extra_parameters: m.extra_parameters,
        utterance: m.utterance,
        slug: 'empty',
      };

      if (
        lastMsg.stepFlow === 'debt_check' &&
        lastMsg.parameters?.can_go_back
      ) {
        setBackButton(true);
      } else {
        setBackButton(false);
      }

      if (m.extra_parameters?.progress) {
        setProgress(m.extra_parameters.progress);
      } else if (progress !== null) {
        setProgress(null);
      }
      if (m.extra_parameters?.slug) {
        lastMsg.slug = m.extra_parameters.slug;
      }
      if (m.extra_parameters?.type) {
        lastMsg.answerType = m.extra_parameters.type;
      }
      if (m.extra_parameters?.product_id) {
        lastMsg.answerType = 'byttRankingDetails';
      }
      if (m.input_type === 'redirect') {
        lastMsg.answerType = null;
        if (m.extra_parameters?.target) {
          saveCloseModalUrl(location.pathname);
          window.location.href = handleUrl(m.extra_parameters.target);
        }
      }
      if (m.input_type === 'iframe') {
        lastMsg.answerType = m.input_type;
        let mockUrl = null;
        if (m.mocked_debt_register_url) {
          mockUrl = m.mocked_debt_register_url;
        }
        lastMsg.suggestedResponses = [
          { utterance: m.utterance },
          { mock: mockUrl },
        ];
      }
      if (m.extra_parameters?.info_skip) {
        lastMsg.answerType = null;
      }
      if (m.input_type === 'xredirect') {
        lastMsg.answerType = null;
        saveCloseModalUrl(location.pathname);
        window.location.href = handleUrl(m.utterance);
      }

      // display & hide intro animation
      const introAnimation = m.extra_parameters?.intro_animation
        ? {
            text: m.extra_parameters?.ia_text,
            title: m.extra_parameters?.ia_title || null,
            description: m.extra_parameters?.ia_description || null,
            duration: m.extra_parameters?.ia_duration,
          }
        : undefined;
      if (introAnimation) {
        setAnimation(introAnimation);
        setTimeout(() => setAnimation(undefined), introAnimation.duration);
      }

      setLastMessage(lastMsg);
    }
  }, [messages]);

  const renderAnswerComponent = () => {
    if (initBobLoading || !lastMessage || !lastMessage.answerType) {
      return null;
    }
    if (
      lastMessage.state !== 'STEPPING' &&
      lastMessage.state !== 'PAUSED' &&
      !lastMessage?.extra_parameters?.noInput
    ) {
      return (
        <ChatAnswers
          handleAction={handleBobTalk}
          data={lastMessage}
          chatOptions={{ goal, step }}
        />
      );
    }
    return null;
  };

  const closeModal = () => {
    if (closeUrl) {
      history.push(`${closeUrl}`);
    }
    if (location?.pathname === '/debt-check') {
      history.push(routes.PRODUCTS_REFINANCING);
    }
    closeChatModal();
  };

  return (
    <>
      <ChatHeader
        handleAction={closeModal}
        backButtonAction={() => handleBobTalk({ backAction: 'back' }, true)}
        progress={progress}
        closeDisabled={lastMessage?.extra_parameters?.close_disabled || false}
        canGoBack={backButton || false}
        animation={animation || wfrAnimation}
      />

      <div
        className={classes.chatContainer}
        id={lastMessage ? lastMessage.slug : 'empty'}
      >
        <Container disableGutters className={classes.chat} maxWidth="sm">
          {animation || wfrAnimation ? (
            <AnimatePresence exitBeforeEnter>
              <lazyMotion.div
                variants={loaderVariants}
                animate="visible"
                initial="hidden"
                exit="exit"
                className={classes.loader}
              >
                <CircularLoader
                  text={wfrAnimation ? wfrAnimation.text : animation.text}
                  title={wfrAnimation ? wfrAnimation.title : animation.title}
                  description={
                    wfrAnimation
                      ? wfrAnimation.description
                      : animation.description
                  }
                />
              </lazyMotion.div>
            </AnimatePresence>
          ) : (
            <>
              <ChatBubbles messages={messages} />
              {renderAnswerComponent()}
            </>
          )}
        </Container>
      </div>
    </>
  );
};

Chat.propTypes = {
  goal: PropTypes.string.isRequired,
  step: PropTypes.string.isRequired,
  clientKnowledge: PropTypes.any,
  firstMessage: PropTypes.object,
};

Chat.defaultProps = {
  clientKnowledge: null,
  firstMessage: null,
};

export default Chat;

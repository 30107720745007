export const mainRoutes = {
  HOME: '/',
  PRODUCTS_REFINANCING: '/refinansiering',
  MY_ECONOMY: '/din-okonomi',
  PRODUCTS_INSURANCE: '/forsikring/:flow?/:step?',
  PRODUCTS_REFINANCING_CHAT: '/refinansiering/:flow/:step', // separate route to avoid conflict with PRODUCTS_FAQ_ARTICLE route
  PRODUCTS_LOAN: '/forbrukslaan',
  LIVE_OFFERS: '/tilbud/:appId',
  PRODUCTS_FAQ_ARTICLE: '/:product(refinansiering)/:articleName',
  ABOUT: '/betaler-du-mer-enn-du-maa',
  CUSTOMER_SERVICE: '/kundeservice',
  TERMS_OF_USE: '/brukervilkar',
  TERMS_OF_USE_MIN_SCOOPR: '/brukervilkar-min-scoopr',
  TERMS_OF_USE_REFINANCING: '/brukervilkar-scoopr-refinansiering',
  TERMS_OF_USE_MORTGAGE: '/brukervilkar-scoopr-boliglan',
  PRIVACY: '/personvernerkaering',
  PRIVACY_STATEMENT: '/personvernerklaering',
  COOKIES: '/cookies',
  INSURANCE_DATA_POLICY: '/brukervilkar-forsikring',
  ERROR_PAGE: '/oops',
  ABANDONED_SESSIONS_CHAT: '/soknad/:flow/:step',
  VERIFIED_REFINANCING: '/debt-check/:flow?/:step?',
  FLOW_SANDBOX: '/flowsandbox/:flow/:step/:product?/:productValue?',
};

export const economyPagesDisabled = {
  LOAN_MONEY: '/lane-penger',
  UNSECURED_LOAN: '/laan-uten-sikkerhet',
  BEST_CONSUMER_LOAN: '/beste-forbrukslan',
  LOAN_CALCULATOR: '/forbrukslaan-kalkulator',
  CONSUMER_LOAN_WITH_PAYMENT_REMARK: '/forbrukslan-med-betalingsanmerkning',
  CHEAPEST_LOAN: '/billigste-forbrukslaan',
  ABOUT_LOAN: '/laan',
  PRIVATE_LOAN: '/privatlan',
};

export const myScooprPages = {
  MY_SCOOPR: '/min-scoopr',
  MY_SCOOPR_CONSENTS: '/min-scoopr/consents',
  MY_SCOOPR_LOGIN: '/min-scoopr/login',
  MY_SCOOPR_DASHBOARD: '/min-scoopr/dashboard',
  MY_SCOOPR_MY_CONSENTS: '/min-scoopr/personvern',
  MY_SCOOPR_MY_DEBT: '/min-scoopr/min-gjeld',
  MY_SCOOPR_EMAIL: '/min-scoopr/e-post',
  MY_SCOOPR_SAVINGS_POTENTIAL: '/min-scoopr/mitt-sparepotensiale',
  MY_SCOOPR_LOGIN_LOADING: '/min-scoopr/loading',
  MY_SCOOPR_MY_CREDIT_SCORE: '/min-scoopr/min-kredittscore',
};

export const economyPages = {
  MY_DEBT: '/mingjeld',
  CREDIT_CHECK_MYSELF: '/kredittsjekke-seg-selv',
  CREDIT_CHECK_MYSELF_CHAT: '/kredittsjekke-seg-selv/:flow?/:step?',
  PARTNER_BANKS: '/banker',
  PARTNER_BANK_DETAILS: '/banker/:bankKey',
  DEBT_REGISTER: '/gjeldsregisteret',
  DEBT_INFORMATION: '/norsk-gjeldsinformasjon',
  INTEREST_RATE: '/rente-hva-er-egentlig-det',
  SMALL_LOAN: '/smalan',
  DEBT_FREE: '/gjeldfri',
  CREDIT_CARD_LOAN: '/kredittkortlan',
  DEBT_OVERVIEW: '/gjeldsoversikt',
  REPAYMENT_PERIOD: '/nedbetalingstid',
  COMPARE_LOANS: '/sammenlign-lan',
  REFINANCING_LOANS: '/refinansieringslan',
  COMPARE_CONSUMER_LOANS: '/sammenlign-forbrukslan',
  SAVE_OR_REPAY_LOAN: '/lonner-det-seg-a-spare-eller-nedbetale-lan',
  MICROLOAN: '/slik-fungerer-mikrolan',
  NOMINAL_AND_EFFECTIVE_INTEREST_RATE:
    '/nominell-og-effektiv-rente-hva-er-forskjellen',
};

export const routes = {
  ...mainRoutes,
  ...economyPages,
  ...economyPagesDisabled,
  ...myScooprPages,
};

export default routes;

import makeStyles from '@mui/styles/makeStyles';

export default makeStyles(() => ({
  stickBottom: {
    width: '100%',
    position: 'fixed',
    bottom: 0,
    zIndex: 1299,
    '& > .MuiBottomNavigationAction-root > .MuiBottomNavigationAction-wrapper > span':
      {
        fontSize: '0.600rem',
        color: '#9AA3B0',
      },
    '& > .MuiBottomNavigationAction-root > .MuiBottomNavigationAction-wrapper > svg':
      {
        fill: 'none',
        '& > g > path': {
          stroke: '#9AA3B0',
        },
      },
    '& > .Mui-selected > .MuiBottomNavigationAction-wrapper > svg': {
      fill: 'none',
      '& > g > path': {
        stroke: '#000 !important',
      },
    },
    '& > .Mui-selected > .MuiBottomNavigationAction-wrapper > span': {
      fontSize: '0.600rem',
      color: '#000',
    },

    '& > .MuiBottomNavigationAction-root': {
      borderTop: '2px solid transparent',
      maxWidth: 112,
    },
    '& > .Mui-selected': {
      borderTop: '2px solid #18A0FB',
    },
  },
}));

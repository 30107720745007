import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import { HERO_VARIANT } from '../../hooks/GetHero';

import useStyles from './Section.styles';

const Section = ({
  children,
  background,
  overflow,
  fullWidth,
  fullHeight,
  last,
  extraPadding,
}) => {
  const classes = useStyles({
    overflow,
    last,
    fullWidth,
    fullHeight,
    extraPadding,
  });

  if (!children) return null;
  return (
    <section className={clsx(classes.sectionRoot, classes[background])}>
      {children}
    </section>
  );
};

Section.propTypes = {
  background: PropTypes.oneOf([
    'default',
    'contrast',
    'paper',
    'primary',
    'secondary',
    'light-gray',
    `gradient-${HERO_VARIANT.BLUE}`,
    `gradient-${HERO_VARIANT.RED}`,
    `gradient-${HERO_VARIANT.YELLOW}`,
  ]),
  overflow: PropTypes.oneOf(['visible', 'hidden']),
  children: PropTypes.node,
  fullWidth: PropTypes.bool,
  fullHeight: PropTypes.bool,
  last: PropTypes.bool, // last section on page
  extraPadding: PropTypes.bool,
};

Section.defaultProps = {
  background: 'default',
  overflow: 'hidden',
  children: null,
  fullWidth: false,
  fullHeight: false,
  last: false,
  extraPadding: false,
};

export default Section;

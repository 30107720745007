export const SIZES = {
  topBar: {
    height: {
      small: 56,
      large: 80,
    },
  },
  navigationChange: 830, // breakpoint
  layout: {
    width: {
      xs: 700,
      sm: 900,
      md: 1200,
      lg: 1400,
      xl: 1500,
    },
  },
};

export const LINKS = {
  LOG_IN: 'https://minside.scoopr.no/',
  FACEBOOK: 'https://www.facebook.com/Scoopr-103103178189677/',
  GJELDSREGISTERET: 'https://gjeldsregisteret.no',
  TRUST_PILOT: 'https://no.trustpilot.com/review/scoopr.no',
};

export const getMenuItems = (appStrings, routes) => [
  {
    title: appStrings.MENU.PRODUCTS_REFINANCING,
    href: routes.PRODUCTS_REFINANCING,
    key: 'refin',
  },
  {
    title: appStrings.MENU.MY_SCOOPR,
    href: routes.MY_SCOOPR_DASHBOARD,
    key: 'myScoopr',
  },
  {
    title: appStrings.MENU.MY_ECONOMY,
    href: routes.MY_ECONOMY,
    key: 'your-economy',
  },
  {
    title: appStrings.MENU.CUSTOMER_SERVICE,
    href: routes.CUSTOMER_SERVICE,
    key: 'customer-service',
  },
];

import { alpha } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import { SIZES } from '../../../../assets/constants/constants';

export default makeStyles(
  ({ breakpoints, palette, spacing, shadows, typography }) => ({
    footerRoot: {
      width: '100%',
      backgroundColor: palette.background.contrast,
      color: palette.text.contrast,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    footerContent: {
      padding: spacing(0, 4, 8),
      width: '100%',
      maxWidth: SIZES.layout.width.xl,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      [breakpoints.up('lg')]: {
        padding: spacing(8, 4),
        flexDirection: 'row',
      },
      [breakpoints.up(1560)]: {
        padding: spacing(8, 0),
        margin: spacing(0, 4),
      },
    },
    section: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
    // section: contact
    contactSection: {
      position: 'relative',
      zIndex: 1050,
      margin: spacing(-8, 'auto', 6),
      backgroundColor: ({ footerDefault }) =>
        footerDefault ? palette.background.default : palette.background.paper,
      color: palette.text.default,
      boxShadow: shadows[21],
      borderRadius: 24,
      width: '100%',
      padding: spacing(4, 3.5, 3),
      maxWidth: 320,
      [breakpoints.up('lg')]: {
        margin: 0,
        order: 2,
        height: 'min-content',
      },
    },
    dots: {
      position: 'absolute',
      height: 38,
      width: 38,
      left: '50%',
      top: -24,
      transform: 'translate(-50%, -50%) rotate(-40deg)',
      fill: palette.primary.light,
      '& path': { stroke: palette.primary.light },
      [breakpoints.up('lg')]: {
        height: 30,
        width: 30,
        left: 'unset',
        right: -27,
        transform: 'none',
        fill: palette.neutral.dark,
        '& path': { stroke: palette.neutral.dark },
      },
    },
    contactTitle: {
      fontSize: '1.75rem',
      fontWeight: typography.fontWeightBold,
    },
    contactContainer: {
      display: 'flex',
      margin: spacing(1.5, 0, 3),
      justifyContent: 'center',
    },
    halfCircle: {
      width: 54,
      height: 54,
      marginLeft: -27,
      marginRight: spacing(1.5),
      border: `15px solid ${palette.primary.light}`,
      borderRadius: '50%',
      borderBottomColor: 'transparent',
      borderLeftColor: 'transparent',
      transform: 'rotate(-315deg)',
    },
    contactLinks: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
    textContactLink: {
      fontWeight: typography.fontWeightBold,
      fontSize: '1rem',
      [breakpoints.up('sm')]: {
        fontSize: '1.25rem',
      },
    },
    openHoursTitle: {
      fontSize: '1rem',
      fontWeight: typography.fontWeightBold,
      [breakpoints.up('md')]: {
        fontSize: '1.125rem',
      },
    },
    openHoursTable: {
      width: '100%',
      borderSpacing: 0,
      margin: spacing(1, 0, 3),
      '& td': {
        padding: spacing(0.5, 0),
        fontSize: '0.875rem',
      },
      [breakpoints.up('sm')]: {
        '& td': {
          fontSize: '1rem',
        },
      },
    },
    openHours: {
      textAlign: 'right',
      minWidth: 60,
      verticalAlign: 'bottom',
    },
    openHoursLine: {
      backgroundColor: palette.primary.light,
      color: palette.primary.light,
      border: 'none',
      height: 2,
    },
    contactButton: {
      margin: (0, 'auto'),
    },
    // section: address and links
    mainSection: {
      width: '100%',
      justifyContent: 'space-between',
      [breakpoints.up(360)]: {
        flexDirection: 'row',
      },
      [breakpoints.up('lg')]: {
        paddingRight: spacing(2),
        order: 1,
        textAlign: 'left',
        alignItems: 'flex-start',
      },
    },
    addressSection: {
      '& > *': { fontStyle: 'normal' }, // address tag
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      textAlign: 'center',
      width: '100%',
      marginBottom: spacing(4),
      [breakpoints.up(360)]: {
        alignItems: 'flex-start',
        textAlign: 'left',
        width: '48%',
        marginBottom: 0,
      },
      [breakpoints.up(820)]: {
        width: '32%',
      },
      [breakpoints.up('lg')]: {
        width: '28%',
      },
    },
    linksSection: {
      display: 'flex',
      alignItems: 'center',
      textAlign: 'center',
      flexDirection: 'column',
      width: '100%',
      [breakpoints.up(360)]: {
        alignItems: 'flex-start',
        textAlign: 'left',
        width: '48%',
      },
      [breakpoints.up(820)]: {
        flexWrap: 'wrap',
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '65%',
      },
      [breakpoints.up('lg')]: {
        width: '70%',
      },
    },
    logo: {
      height: 24,
      marginBottom: spacing(1),
      [breakpoints.up(820)]: {
        marginTop: spacing(6),
      },
      [breakpoints.up('lg')]: {
        margin: spacing(0, 0, 3),
      },
    },
    title: {
      color: palette.background.light,
      margin: spacing(1, 0),
    },
    textAddress: {
      marginTop: spacing(2),
    },
    termsLinksContainer: {
      display: 'flex',
      flexDirection: 'column',
      margin: spacing(6, 0),
      [breakpoints.up(820)]: {
        display: 'none',
      },
    },

    buttonFacebook: {
      color: palette.text.contrast,
      margin: spacing(3, 0, 0, -1),
      '&:hover': {
        backgroundColor: alpha(palette.background.default, 0.1),
      },
      '&:focus-visible': {
        backgroundColor: alpha(palette.background.default, 0.1),
      },
      '& svg': {
        width: 24,
        height: 24,
      },
    },
    linksList: {
      display: 'flex',
      flexDirection: 'column',
      [breakpoints.up(820)]: {
        width: '50%',
      },
    },
    link: {
      color: palette.text.contrast,
      ...typography.p2,
      margin: spacing(0.75, 0),
    },

    // bottom bar
    fotterBottom: {
      backgroundColor: '#0C2346',
      display: 'flex',
      justifyContent: 'center',
      width: '100%',
      height: 70,
      [breakpoints.up('sm')]: {
        height: 90,
      },
    },
    fotterBottomContent: {
      margin: spacing(0, 4),
      maxWidth: SIZES.layout.width.xl,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      [breakpoints.up(820)]: {
        justifyContent: 'space-between',
      },
    },
    footerLinks: {
      display: 'none',
      [breakpoints.up(820)]: {
        display: 'flex',
        alignItems: 'center',
      },
    },
    footerBottomLink: {
      color: palette.text.contrast,
      ...typography.p2,
    },
    dot: {
      backgroundColor: '#354560',
      height: 4,
      width: 4,
      margin: spacing(3),
    },
  }),
);

/* eslint-disable no-nested-ternary */
import makeStyles from '@mui/styles/makeStyles';

import { SIZES } from '../../assets/constants/constants';

export default makeStyles(({ breakpoints, palette, spacing }) => ({
  sectionRoot: {
    position: 'relative',
    overflow: ({ overflow }) => overflow,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',

    '& > *': {
      maxWidth: ({ fullWidth }) =>
        fullWidth ? undefined : SIZES.layout.width.md,
      zIndex: ({ overflow }) => (overflow === 'visible' ? 1050 : 2),
    },
    '&$contrast': {
      backgroundColor: palette.background.contrast,
    },
    '&$default': {
      backgroundColor: palette.background.default,
    },
    '&$paper': {
      backgroundColor: palette.background.paper,
    },
    '&$primary': {
      backgroundColor: palette.primary.main,
    },
    '&$secondary': {
      backgroundColor: palette.secondary.main,
    },
    '&$light-gray': {
      backgroundColor: palette.background.lightest,
    },
    '&$gradient-blue': {
      background: `radial-gradient(98.73% 52.1% at 47.33% 51.23%, rgba(255, 255, 255, 0.49) 0%, rgba(255, 255, 255, 0) 97.5%), ${palette.primary.main}`,
      [breakpoints.up('md')]: {
        background: `radial-gradient(61.37% 762.47% at 80.21% 50%, rgba(255, 255, 255, 0.46) 0%, rgba(255, 255, 255, 0.01) 67.16%, rgba(255, 255, 255, 0.01) 100%),  ${palette.primary.main}`,
      },
    },
    '&$gradient-yellow': {
      background: `radial-gradient(98.73% 52.1% at 47.33% 51.23%, rgba(255, 255, 255, 0.49) 0%, rgba(255, 255, 255, 0) 97.5%), ${palette.secondary.main}`,
      [breakpoints.up('md')]: {
        background: `radial-gradient(61.37% 762.47% at 80.21% 50%, rgba(255, 255, 255, 0.46) 0%, rgba(255, 255, 255, 0.01) 67.16%, rgba(255, 255, 255, 0.01) 100%), ${palette.secondary.main}`,
      },
    },
    '&$gradient-red': {
      background: `radial-gradient(98.73% 52.1% at 47.33% 51.23%, rgba(255, 255, 255, 0.49) 0%, rgba(255, 255, 255, 0) 97.5%), ${palette.accent.main}`,
      [breakpoints.up('md')]: {
        background: `radial-gradient(61.37% 762.47% at 80.21% 50%, rgba(255, 255, 255, 0.46) 0%, rgba(255, 255, 255, 0.01) 67.16%, rgba(255, 255, 255, 0.01) 100%), ${palette.accent.main}`,
      },
    },

    padding: ({ last, fullHeight, fullWidth, extraPadding }) =>
      spacing(
        fullHeight ? 0 : extraPadding ? 10 : 4,
        fullWidth ? 0 : 4,
        fullHeight ? 0 : last ? 18 : extraPadding ? 10 : 4,
      ),
    [breakpoints.up('sm')]: {
      padding: ({ last, fullHeight, fullWidth, extraPadding }) =>
        spacing(
          fullHeight ? 0 : extraPadding ? 12 : 6,
          fullWidth ? 0 : 4,
          fullHeight ? 0 : last ? 22 : extraPadding ? 12 : 6,
        ),
    },
    [breakpoints.up('md')]: {
      padding: ({ last, fullHeight, fullWidth, extraPadding }) =>
        spacing(
          fullHeight ? 0 : extraPadding ? 14 : 8,
          fullWidth ? 0 : 10,
          fullHeight ? 0 : last ? 25 : extraPadding ? 18 : 8,
        ),
    },
    [breakpoints.up('lg')]: {
      paddingBottom: ({ last, fullHeight, extraPadding }) =>
        spacing(fullHeight ? 0 : last ? 12 : extraPadding ? 18 : 8),
    },
  },
  contrast: {},
  default: {},
  paper: {},
  primary: {},
  secondary: {},
  'gradient-blue': {},
  'gradient-yellow': {},
  'gradient-red': {},
  'light-gray': {},
}));

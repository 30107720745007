export const EVENTS = {
  setPageTitle: {
    action: 'pagetitle_set',
    category: 'tracking',
    event: 'hd.pagetitle_set',
  },
  setUser: {
    action: 'hd.userid_set',
    category: 'auth',
    event: 'hd.userid_set',
  },
  refinLandingPageMainCta: {
    action: 'refin_landing_main_CTA_clicked',
    category: 'goal_flow',
    event: 'hd.refin_landing_main_CTA_clicked',
  },
  refinLandingPageSecondaryCta: {
    action: 'refin_landing_secondary_CTA_clicked',
    category: 'goal_flow',
    event: 'hd.refin_landing_secondary_CTA_clicked',
  },
  goActivate: {
    event: 'optimize.activate',
  },
  initFB: {
    event: 'fb_init',
  },
  backButton: {
    action: 'back_button_clicked',
    category: 'goal_flow',
    event: 'back_button_clicked',
  },
  userLogin: {
    action: 'user_login',
    category: 'tracking',
    event: 'user_login',
  },
  userLoginStarted: {
    action: 'user_login_started',
    category: 'tracking',
    event: 'user_login_started',
  },
  userLoginGjeldsStarted: {
    action: 'main_cta_clicked',
    category: 'tracking',
    event: 'main_cta_clicked',
    label: 'Fortsett med BankID',
  },
  userGjeldsClick: {
    action: 'main_cta_clicked',
    category: 'tracking',
    event: 'main_cta_clicked',
    label: 'Sjekk min gjeld',
  },
  userLoginCompleted: {
    action: 'login',
    category: 'tracking',
    event: 'login',
  },
  userLogout: {
    action: 'logout',
    category: 'tracking',
    event: 'logout',
  },
  userDeleted: {
    action: 'account_deleted',
    category: 'tracking',
    event: 'account_deleted',
  },
  topCTAClick: {
    action: 'top_cta_clicked',
    category: 'tracking',
    event: 'top_cta_clicked',
  },
  mSInitMortgage: {
    action: 'dash_main_cta_mortgage_clicked',
    category: 'tracking',
    event: 'dash_main_cta_mortgage_clicked',
  },
  mSInitDebtCheck: {
    action: 'dash_main_cta_refin_clicked',
    category: 'tracking',
    event: 'dash_main_cta_refin_clicked',
  },
  mSInitLoans: {
    action: 'dash_main_cta_cash_clicked',
    category: 'tracking',
    event: 'dash_main_cta_cash_clicked',
  },
  mSEmailAsked: {
    action: 'email_asked',
    category: 'tracking',
    event: 'email_asked',
  },
  mSConsentMarketingAsked: {
    action: 'consent_marketing_asked',
    category: 'tracking',
    event: 'consent_marketing_asked',
  },
  mSEmailSubmitted: {
    action: 'email_submitted',
    category: 'tracking',
    event: 'email_submitted',
  },
  mSConsentMarketingSubmitted: {
    action: 'consent_marketing_submitted',
    category: 'tracking',
    event: 'consent_marketing_submitted',
  },
  mSBackButton: {
    action: 'go_back_my_scoopr',
    category: 'tracking',
    event: 'go_back_my_scoopr',
  },
  mSCreditInfoGood: {
    action: 'credit_info_good',
    category: 'tracking',
    event: 'credit_info_good',
  },
  mSCreditInfoBad: {
    action: 'credit_info_bad',
    category: 'tracking',
    event: 'credit_info_bad',
  },
  mSCreditInfoNa: {
    action: 'credit_info_na',
    category: 'tracking',
    event: 'credit_info_na',
  },
  mSDebtInfoWithSavings: {
    action: 'debt_info_with_savings',
    category: 'tracking',
    event: 'debt_info_with_savings',
  },
  mSDebtInfoNoSavings: {
    action: 'debt_info_with_no_savings',
    category: 'tracking',
    event: 'debt_info_with_no_savings',
  },
  mSDebtInfoNa: {
    action: 'debt_info_na',
    category: 'tracking',
    event: 'debt_info_na',
  },
  mSConsentCreditAndDebtAsked: {
    action: 'consent_creditanddebt_asked',
    category: 'tracking',
    event: 'consent_creditanddebt_asked',
  },
  mSConsentCreditAndDebtSubmitted: {
    action: 'consent_creditanddebt_submitted',
    category: 'tracking',
    event: 'consent_creditanddebt_submitted',
  },
  mSConsentCreditAndDebtRejected: {
    action: 'consent_creditanddebt_rejected',
    category: 'tracking',
    event: 'consent_creditanddebt_rejected',
  },
};

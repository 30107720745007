import makeStyles from '@mui/styles/makeStyles';

export default makeStyles(({ palette, spacing, typography }) => ({
  bubbleContainer: {
    display: 'flex',
    alignSelf: 'flex-end',
    backgroundColor: palette.chat.bubble.user.background,
    border: `2px solid ${palette.chat.bubble.user.border}`,
    padding: spacing(2),
    borderRadius: 8,
    boxShadow: '0px 1px 3px rgba(24, 160, 251, 0.12)',
    marginBottom: spacing(2.5),
    flex: 0,
  },
  bubbleContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    color: palette.chat.bubble.user.text,
    ...typography.p1,

    '& > p': {
      margin: 0,
    },
  },
}));

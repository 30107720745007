import React from 'react';
import PropTypes from 'prop-types';

import ActionButton from '../../../ActionButton';
import useBrainSession from '../../../../services/BrainSession/useBrainSession';

const MenuActionButton = ({ className, onClick, sideBar }) => {
  const { toggleChat } = useBrainSession();
  return (
    <ActionButton
      label="Søk om lavere rente"
      className={className}
      size="small"
      gaEvent="topCTAClick"
      color="primary"
      flow="debt_check"
      step="skip_start"
      onClick={
        onClick && sideBar
          ? () => {
              onClick();
              toggleChat({
                goal: 'debt_check',
                step: 'skip_start',
              });
            }
          : onClick
      }
    />
  );
};

MenuActionButton.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.any,
  sideBar: PropTypes.bool,
};

MenuActionButton.defaultProps = {
  className: undefined,
  onClick: undefined,
  sideBar: false,
};

export default MenuActionButton;

import React from 'react';
import clsx from 'clsx';
import { m as lazyMotion } from 'framer-motion';
import { Box, IconButton } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import KeyboardArrowRightRoundedIcon from '@mui/icons-material/KeyboardArrowRightRounded';

import { ReactComponent as MyDebtIcon } from '../../assets/images/myEconomy/my_debt.svg';
import { ReactComponent as CreditCheckMyselfIcon } from '../../assets/images/myEconomy/credit_check.svg';
import { ReactComponent as BanksIcon } from '../../assets/images/myEconomy/banks.svg';
import { ReactComponent as CheapestLoanIcon } from '../../assets/images/myEconomy/cheapest_loan.svg';
import { ReactComponent as LoanIcon } from '../../assets/images/myEconomy/loan.svg';
import { ReactComponent as DebtInformationIcon } from '../../assets/images/myEconomy/debt_information.svg';
import { ReactComponent as DebtRegisterIcon } from '../../assets/images/myEconomy/debt_register.svg';
import Page from '../../components/Page';
import ActionButton from '../../components/ActionButton';
import Section from '../../components/Section';
import Typography from '../../components/Typography';
import { LANG } from '../../services/Config';
import { palette } from '../../assets/styles/theme';
import SectionTitle from '../../components/SectionTitle/SectionTitle';

import useStyles from './MyEconomy.styles';

const variants = {
  hover: {
    clipPath: `circle(1500px at 60px 60px)`,
    backgroundColor: palette.secondary.main,
    transition: {
      type: 'spring',
      stiffness: 20,
      restDelta: 2,
    },
  },
  closed: {
    clipPath: `circle(0px at 60px 60px)`,
    backgroundColor: 'transparent',
    transition: {
      ease: 'easeIn',
      type: 'spring',
      stiffness: 100,
    },
  },
};
// const ECONOMY_CATEGORIES = [
// example:
//   { id: 1, label: 'Label_1' },
//   { id: 2, label: 'Label_2' },
// ];

export const ECONOMY_PAGES = [
  // example:
  // { name: 'Page name', icon: TestIcon, categoryId: 1 },
  { name: 'MY_DEBT', icon: MyDebtIcon },
  { name: 'CREDIT_CHECK_MYSELF', icon: CreditCheckMyselfIcon },
  { name: 'PARTNER_BANKS', icon: BanksIcon },
  { name: 'DEBT_REGISTER', icon: DebtRegisterIcon },
  { name: 'DEBT_INFORMATION', icon: DebtInformationIcon },
  { name: 'INTEREST_RATE', icon: LoanIcon },
  { name: 'SMALL_LOAN', icon: CheapestLoanIcon },
  { name: 'DEBT_FREE', icon: CheapestLoanIcon },
  { name: 'CREDIT_CARD_LOAN', icon: CheapestLoanIcon },
  { name: 'DEBT_OVERVIEW', icon: CheapestLoanIcon },
  { name: 'REPAYMENT_PERIOD', icon: CheapestLoanIcon },
  { name: 'COMPARE_LOANS', icon: CheapestLoanIcon },
  { name: 'REFINANCING_LOANS', icon: CheapestLoanIcon },
  { name: 'COMPARE_CONSUMER_LOANS', icon: CheapestLoanIcon },
  { name: 'SAVE_OR_REPAY_LOAN', icon: CheapestLoanIcon },
  { name: 'MICROLOAN', icon: CheapestLoanIcon },
  { name: 'NOMINAL_AND_EFFECTIVE_INTEREST_RATE', icon: CheapestLoanIcon },
];

const MyEconomy = () => {
  const classes = useStyles();
  const { appStrings, routes } = LANG;

  const renderCard = ({ name, icon: Icon }, withCategories) => (
    <RouterLink
      key={name}
      to={routes[name]}
      className={clsx(
        classes.cardLink,
        withCategories && classes.withCategories,
      )}
    >
      <lazyMotion.div
        initial="closed"
        whileHover="hover"
        className={classes.card}
      >
        <lazyMotion.span
          variants={variants}
          className={classes.animationBackground}
        />
        <div className={classes.cardContent}>
          <Icon className={classes.icon} />
          <Typography variant="p1SemiBold">{appStrings.MENU[name]}</Typography>
          <IconButton className={classes.arrowButton} size="large">
            <KeyboardArrowRightRoundedIcon />
          </IconButton>
        </div>
      </lazyMotion.div>
    </RouterLink>
  );

  return (
    <Page title={appStrings.MENU.MY_ECONOMY}>
      <Section last background="paper">
        <SectionTitle title={appStrings.MENU.MY_ECONOMY} />

        {/* list without categories */}
        <div className={classes.cardsListContainer}>
          {ECONOMY_PAGES.map(page => renderCard(page, false))}
        </div>

        {/* list with categories */}
        {/* <div className={classes.container}>
          {ECONOMY_CATEGORIES.map(({ id, label }) => (
            <div key={id} className={classes.categoryContainer}>
              <Typography className={classes.categoryTitle}>{label}</Typography>
              <div
                className={clsx(
                  classes.cardsListContainer,
                  classes.withCategories,
                )}
              >
                {ECONOMY_PAGES.filter(i => i.categoryId === id).map(page =>
                  renderCard(page, true),
                )}
              </div>
            </div>
          ))}
        </div> */}

        <Typography variant="h3" className={classes.openChatTitle}>
          Se om du kan få en bedre deal på forsikring, forbrukslån eller
          refinansiering.
        </Typography>
        <Box display="flex" justifyContent="center" mt={6}>
          <ActionButton
            label="Sjekk hvor mye du kan spare"
            step="init_with_fixed_start"
            flow="mobile_from_external"
            color="secondary"
          />
        </Box>
      </Section>
    </Page>
  );
};

export default MyEconomy;

import makeStyles from '@mui/styles/makeStyles';

export default makeStyles(() => ({
  root: {
    height: '100%',
    overflow: 'hidden',
    width: '100%',
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    flex: '1 1 auto',
    overflow: 'hidden',
    height: '100%',
    flexGrow: 1,
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    // justifyContent: 'center',
    width: '100%',
    flex: 1,
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
  },
}));

import React, { createContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import TagManager from 'react-gtm-module';
import { useSocketIO } from 'react-use-websocket';

import { API, GTM } from '../Config';

import { EVENTS } from './events.const';

const TrackingContext = createContext(undefined);

const { hostname } = window.location;

const envs = {
  dev: {
    auth: 'Qt1PyqNroNqhIHL5HuzlxQ',
    preview: 'env-7',
  },
  prod: {
    auth: 'xD4PUOPlW6TgqTuA3fxYOg',
    preview: 'env-2',
  },
  alpha: {
    auth: 'SmCkqVYrYDiyX39pSOE11g',
    preview: 'env-33',
  },
  poc: {},
};

const envVariable = {
  localhost: { ...envs.dev },
  'hudya.no': { ...envs.prod },
  'scoopr.no': { ...envs.prod },
  'dev.scoopr.no': { ...envs.dev },
  'alpha.scoopr.no': { ...envs.poc },
  'sandbox.scoopr.no': { ...envs.poc },
};

function TrackingProvider({ children }) {
  const [loading, setLoading] = useState(true);
  const [dataLayer, setDataLayer] = useState({});
  const [backendTests, setBackendTests] = useState({});
  const [gaClientId, setGaClientId] = useState('');
  const [isWSInit, setIsWSInit] = useState(false);
  const {
    sendMessage: wsSendMessage,
    lastMessage: wsLastMessage,
    readyState: wsReadyState,
  } = useSocketIO(API.ws);

  const getGaClientId = () => {
    if (!window.ga) {
      return '';
    }
    let clientId = '';
    window.ga(() => {
      clientId = window.ga.getAll()[0].get('clientId');
    });
    return clientId;
  };

  const initWS = id => {
    if (!isWSInit) {
      wsSendMessage(`42["init",{"foreignId":"${id}"}]`);
      setIsWSInit(true);
    }
  };

  const initFacebookPixel = value => {
    addEvent('initFB');
  };

  useEffect(() => {
    TagManager.initialize({
      gtmId: GTM.id,
      ...envVariable[hostname],
    });

    setLoading(false);
    setDataLayer(window.dataLayer);
    setGaClientId(getGaClientId());
  }, []);

  const addEvent = (action, parameters) => {
    let dataLayerObj = {};
    if (EVENTS[action]) {
      dataLayerObj = { ...EVENTS[action] };
    }
    if (parameters) {
      dataLayerObj = { ...dataLayerObj, ...parameters };
    }
    TagManager.dataLayer({
      dataLayer: dataLayerObj,
    });
  };

  const addValue = (value, parameters) => {
    let dataLayerObj = {};
    if (value) {
      dataLayerObj = { ...value };
    }
    if (parameters) {
      dataLayerObj = { ...dataLayerObj, ...parameters };
    }
    TagManager.dataLayer({
      dataLayer: dataLayerObj,
    });
  };

  const value = {
    loading,
    dataLayer,
    addEvent,
    addValue,
    initFacebookPixel,
    gaClientId,
    getGaClientId,
    wsSendMessage,
    wsLastMessage,
    wsReadyState,
    initWS,
    isWSInit,
    backendTests,
    setBackendTests,
  };

  return (
    <TrackingContext.Provider value={value}>
      {children}
    </TrackingContext.Provider>
  );
}

TrackingProvider.propTypes = {
  children: PropTypes.node,
};

TrackingProvider.defaultProps = {
  children: null,
};

export { TrackingContext, TrackingProvider };

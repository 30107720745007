import makeStyles from '@mui/styles/makeStyles';

export default makeStyles(
  ({ breakpoints, palette, shadows, shape, spacing }) => ({
    container: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      [breakpoints.up('lg')]: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
      },
    },
    cardsListContainer: {
      display: 'flex',
      width: '100%',
      maxWidth: 700,

      flexDirection: 'column',
      [breakpoints.up(850)]: {
        maxWidth: 'unset',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
      },
      [breakpoints.up('lg')]: {
        maxWidth: 1100,
      },

      '&$withCategories': {
        maxWidth: 1300,
        [breakpoints.up('lg')]: {
          flexDirection: 'column',
        },
      },
    },
    withCategories: {},
    categoryContainer: {
      width: '100%',
      maxWidth: 700,
      display: 'flex',
      flexDirection: 'column',
      [breakpoints.up(850)]: {
        maxWidth: 'unset',
      },
      [breakpoints.up('lg')]: {
        flexWrap: 'nowrap',
        width: '31.5%',
      },
    },
    categoryTitle: {
      fontSize: '1.125rem',
      marginTop: spacing(3),
      marginBottom: spacing(1.5),
      [breakpoints.up('lg')]: {
        marginTop: 0,
      },
    },

    cardLink: {
      width: '100%',
      textDecoration: 'none',
      marginBottom: spacing(3),
      [breakpoints.up(850)]: {
        width: '49%',
      },
      '&$withCategories': {
        [breakpoints.up('lg')]: {
          width: '100%',
        },
      },
    },
    card: {
      boxShadow: shadows[21],
      color: palette.text.default,
      position: 'relative',
      borderRadius: shape.borderRadius,
      display: 'flex',
      minHeight: 80,
      [breakpoints.up('sm')]: {
        height: 120,
      },
    },
    animationBackground: {
      position: 'absolute',
      width: '100%',
      height: '100%',
      borderRadius: shape.borderRadius,
    },
    cardContent: {
      display: 'flex',
      alignItems: 'center',
      wordBreak: 'break-word',
      width: '100%',
      padding: spacing(1.5),
      [breakpoints.up(300)]: {
        padding: spacing(1.5, 2),
        wordBreak: 'unset',
      },
      [breakpoints.up('sm')]: {
        padding: spacing(1.5, 4),
      },
      '& > *': { zIndex: 1 },
    },
    icon: {
      width: 40,
      minWidth: 40,
      height: 40,
      marginRight: spacing(2),
      [breakpoints.up('sm')]: {
        width: 56,
        minWidth: 56,
        height: 56,
        marginRight: spacing(3),
      },
    },
    arrowButton: {
      marginLeft: 'auto',
      marginRight: spacing(-1.5),
    },
    openChatTitle: {
      maxWidth: 700,
      textAlign: 'center',
      marginTop: spacing(8),
      [breakpoints.up('sm')]: {
        marginTop: spacing(16),
      },
    },
  }),
);

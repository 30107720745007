import makeStyles from '@mui/styles/makeStyles';

import { SIZES } from '../../../../assets/constants/constants';

export default makeStyles(({ palette, breakpoints, spacing }) => ({
  appBar: {
    alignItems: 'center',
    padding: spacing(0, 2),
    [breakpoints.up('md')]: {
      padding: spacing(0, 4),
    },
  },
  minScoopr: {
    [breakpoints.down('sm')]: {
      background: palette.background.default,
    },
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'center',
    position: 'relative',
    height: SIZES.topBar.height.small,
    minHeight: SIZES.topBar.height.small,
    width: '100%',
    maxWidth: SIZES.layout.width.xl,
    padding: 0,
    [breakpoints.up(SIZES.navigationChange)]: {
      justifyContent: 'space-between',
      height: SIZES.topBar.height.large,
      minHeight: SIZES.topBar.height.large,
    },
  },
  openNavButton: {
    position: 'absolute',
    left: spacing(1),
    '& svg': {
      fontSize: '1,875rem',
    },
    [breakpoints.up(SIZES.navigationChange)]: {
      display: 'none',
    },
  },
  logo: {
    marginTop: spacing(1),
    height: 18,
    width: 109,
    [breakpoints.up('md')]: {
      height: 25,
    },
  },
  menuContainer: {
    display: 'none',
    [breakpoints.up(SIZES.navigationChange)]: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
  },
  tabsRoot: {
    height: SIZES.topBar.height.large,
  },
  menuIndicator: {
    borderRadius: 4,
    height: 4,
  },
  button: {
    marginLeft: spacing(3),
  },
}));

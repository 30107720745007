import Cookies from 'js-cookie';

import { getUserAgent } from '../Utils';

const getSourceFormLocation = () => {
  // eslint-disable-next-line no-useless-escape
  const regex = /([^\?]+)(\?.*)?/;

  let sourceName = '';
  const params = new URLSearchParams(window.location.search);
  if (params.get('sourceid')) {
    sourceName = params.get('sourceid');
  } else if (params.get('utm_source')) {
    sourceName = params.get('utm_source');
  } else if (document.referrer !== '') {
    // eslint-disable-next-line prefer-destructuring
    sourceName = document.referrer.match(regex)[1];
  } else {
    sourceName = 'direct';
  }
  const customData = {
    params: {},
    httpReferrer: '',
    gaCookie: '',
    userAgent: '',
    fbp: '',
    fbc: '',
    gcl_au: '',
    gcl_aw: '',
    gcl_dc: '',
  };
  // eslint-disable-next-line no-restricted-syntax
  for (const pair of params.entries()) {
    // eslint-disable-next-line prefer-destructuring
    customData.params[pair[0]] = pair[1];
  }
  customData.httpReferrer = document.referrer;
  customData.gaCookie = Cookies.get('_ga') || '';
  customData.userAgent = getUserAgent() || '';
  customData.fbp = Cookies.get('_fbp') || '';
  customData.fbc = Cookies.get('_fbc') || '';
  customData.gcl_au = Cookies.get('_gcl_au') || '';
  customData.gcl_aw = Cookies.get('_gcl_aw') || '';
  customData.gcl_dc = Cookies.get('_gcl_dc') || '';
  return { customData, sourceName };
};

export { getSourceFormLocation };

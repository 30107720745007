import React from 'react';
import PropTypes from 'prop-types';
import { IconButton } from '@mui/material';
import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded';

import Logo from '../../../../../Logo';
import closeXIcon from '../../../../../../assets/images/close_x.svg';
import useTracking from '../../../../../../services/Tracking/useTracking';

import useStyles from './SimpleChatHeader.styles';

const SimpleChatHeader = ({
  handleAction,
  withClose,
  backButtonAction,
  canGoBack,
  animation,
}) => {
  const { addEvent } = useTracking();
  const classes = useStyles();

  const backAction = () => {
    addEvent('backButton');
    backButtonAction({ payload: 'BACK' }, true);
  };

  return (
    <div className={classes.chatHeaderContent}>
      {canGoBack && !animation ? (
        <div className={classes.backButton}>
          <IconButton
            aria-label="back"
            onClick={() => backAction()}
            size="large"
          >
            <ArrowBackIosRoundedIcon />
          </IconButton>
        </div>
      ) : null}
      <Logo className={classes.logo} />
      <div className={classes.icon}>
        {withClose && !animation ? (
          <IconButton
            aria-label="close"
            onClick={() => handleAction()}
            size="large"
          >
            <img src={closeXIcon} alt="" />
          </IconButton>
        ) : null}
      </div>
    </div>
  );
};

SimpleChatHeader.propTypes = {
  handleAction: PropTypes.func.isRequired,
  backButtonAction: PropTypes.func,
  withClose: PropTypes.bool,
  stepFlow: PropTypes.any,
  animation: PropTypes.any,
};

SimpleChatHeader.defaultProps = {
  withClose: true,
  backButtonAction: undefined,
  stepFlow: null,
  animation: false,
};

export default SimpleChatHeader;

import { alpha } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import { SIZES } from '../../assets/constants/constants';

export default makeStyles(({ breakpoints, palette, spacing }) => ({
  infoBoxContainer: {
    backgroundColor: palette.background.primary,
    color: palette.text.contrast,
    position: 'fixed',
    padding: spacing(2),
    top: SIZES.topBar.height.small,
    zIndex: 2000,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    [breakpoints.up(SIZES.navigationChange)]: {
      top: SIZES.topBar.height.large,
    },
  },
  paddingPlaceholder: {
    minHeight: ({ offsetTop }) =>
      offsetTop === 0 ? SIZES.topBar.height.small : offsetTop,
    [breakpoints.up(SIZES.navigationChange)]: {
      minHeight: ({ offsetTop }) =>
        offsetTop === 0 ? SIZES.topBar.height.large : offsetTop,
    },
  },
  infoBoxContent: {
    width: '100%',
    display: 'flex',
    alignItems: 'flex-start',
    maxWidth: SIZES.layout.width.xl,
  },
  iconConatiner: {
    backgroundColor: alpha(palette.background.paper, 0.25),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
    minWidth: 24,
    width: 24,
    height: 24,
    fontSize: '1rem',
    fontWeight: 'bold',
    [breakpoints.up('sm')]: {
      minWidth: 36,
      width: 36,
      height: 36,
    },
  },
  text: {
    margin: spacing(0.5, 2),
    [breakpoints.up('sm')]: {
      margin: spacing(1, 2),
    },
  },
  actionContainer: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 'auto',
    marginRight: spacing(-1),
    '& svg': {
      fontSize: '1rem',
    },
    [breakpoints.up('sm')]: {
      marginRight: 0,
      '& svg': {
        fontSize: '1.5rem',
      },
    },
  },
}));

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { AnimatePresence, m as lazyMotion } from 'framer-motion';

import UserBubble from './_components/UserBubble';
import ScooprBubble from './_components/ScooprBubble';
import useStyles from './ChatBubbles.styles';

const variants = {
  hidden: {},
  visible: {
    transition: {
      ease: 'easeInOut',
      when: 'beforeChildren',
    },
  },
  exit: {
    transition: {
      ease: 'easeInOut',
      when: 'afterChildren',
    },
  },
};

const ChatBubbles = ({ messages }) => {
  const classes = useStyles();

  const [preparedMessages, setPreparedMessages] = useState([]);

  useEffect(() => {
    const filtered = messages.filter(
      m =>
        m.content_type !== 'info' &&
        m.input_type !== 'iframe' &&
        m.input_type !== 'xredirect' &&
        m.extra_parameters?.type !== 'potentialSavings' &&
        m.extra_parameters?.type !== 'potentialSavings2' &&
        m.extra_parameters?.type !== 'potentialSavings3' &&
        m.extra_parameters?.type !== 'potentialSavings4' &&
        m.extra_parameters?.type !== 'insuranceOffer' &&
        m.extra_parameters?.type !== 'actionSuccess' &&
        m.extra_parameters?.type !== 'actionError' &&
        m.extra_parameters?.type !== 'insuranceMeeting' &&
        m.extra_parameters?.type !== 'goScoreDownload' &&
        m.extra_parameters?.type !== 'goScoreQuestionsFilled' &&
        m.extra_parameters?.type !== 'ADARedirectLoader' &&
        m.extra_parameters?.type !== 'creditsafeLoader' &&
        m.extra_parameters?.type !== 'creditsafeScore' &&
        m.extra_parameters?.type !== 'creditsafeConsent' &&
        m.extra_parameters?.type !== 'loansPreview' &&
        m.extra_parameters?.type !== 'loansPreview2' &&
        m.extra_parameters?.type !== 'googleOptimize' &&
        m.extra_parameters?.type !== 'debtRegisterLoader' &&
        m.extra_parameters?.type !== 'debtConsent' &&
        m.extra_parameters?.type !== 'debtConsentWithLogos' &&
        m.extra_parameters?.type !== 'debtConsentWithoutLogos' &&
        m.extra_parameters?.type !== 'debtConsentAccountCreation' &&
        m.extra_parameters?.type !== 'loansPreviewTableBottom' &&
        m.extra_parameters?.type !== 'registerDebtFullControlTableBottom' &&
        m.extra_parameters?.type !== 'registerDebtFullControl' &&
        m.extra_parameters?.type !== 'consent' &&
        m.extra_parameters?.type !== 'adaApplicationLoader' &&
        m.extra_parameters?.type !== 'radioButtons' &&
        m.extra_parameters?.type !== 'radioDynamicButtons' &&
        m.extra_parameters?.type !== 'loansPreviewInterest' &&
        !m.extra_parameters?.product_id,
    );
    setPreparedMessages(filtered);
  }, [messages]);

  const renderBubble = ({ user, utterance }) =>
    user ? (
      <UserBubble key={utterance} {...{ utterance }} />
    ) : (
      <ScooprBubble key={utterance} {...{ utterance }} />
    );

  return (
    <AnimatePresence exitBeforeEnter>
      {preparedMessages.length > 0 ? (
        <lazyMotion.div
          key="message"
          className={classes.chatBubblesContainer}
          variants={variants}
          animate="visible"
          initial="hidden"
          exit="exit"
        >
          {preparedMessages.map(b => renderBubble(b))}
        </lazyMotion.div>
      ) : null}
    </AnimatePresence>
  );
};

ChatBubbles.propTypes = {
  messages: PropTypes.arrayOf(PropTypes.object).isRequired,
};

ChatBubbles.defaultProps = {};

export default ChatBubbles;

import React from 'react';
import clsx from 'clsx';
import { Link as RouterLink, matchPath, useLocation } from 'react-router-dom';
import { Button, Hidden, IconButton, Link } from '@mui/material';

import { ReactComponent as Dots } from '../../../../assets/images/dots_doodle.svg';
import { ReactComponent as FacebookLogo } from '../../../../assets/images/facebook.svg';
import Typography from '../../../Typography';
import Logo from '../../../Logo';
import { LANG } from '../../../../services/Config';
import { LINKS } from '../../../../assets/constants/constants';
import { ECONOMY_PAGES } from '../../../../pages/MyEconomy/MyEconomy';

import useStyles from './Footer.styles';

// check path to differentiate footer on specific paths
const checkPath = (pathname, routes) =>
  matchPath(pathname, {
    path: [
      routes.HOME,
      routes.PRODUCTS_MOBILE,
      routes.MY_ECONOMY,
      routes.PARTNER_BANK_DETAILS,
      ...ECONOMY_PAGES.map(({ name }) => routes[name]),
    ],
    exact: true,
  });

const Footer = () => {
  const { appStrings, routes } = LANG;
  const { pathname } = useLocation();
  const classes = useStyles({ footerDefault: checkPath(pathname, routes) });

  const economyLinksLeft = ECONOMY_PAGES.slice(0, ECONOMY_PAGES.length / 2);
  const economyLinksRight = ECONOMY_PAGES.slice(
    ECONOMY_PAGES.length / 2,
    ECONOMY_PAGES.length,
  );

  return (
    <footer className={classes.footerRoot}>
      <div className={classes.footerContent}>
        {/* section: contact */}
        <div className={clsx(classes.section, classes.contactSection)}>
          <Dots className={classes.dots} />
          <Typography
            component="h3"
            variant="h3"
            className={classes.contactTitle}
          >
            {appStrings.CONTACT.TITLE}
          </Typography>

          <div className={classes.contactContainer}>
            <span className={classes.halfCircle} />
            <div className={classes.contactLinks}>
              <Link
                href={`tel:${appStrings.CONTACT.PHONE2}`}
                className={classes.textContactLink}
                underline="hover"
              >
                <Typography component="p" variant="subtitle1">
                  {appStrings.CONTACT.PHONE1}
                </Typography>
              </Link>
              <Link
                href={`mailto:${appStrings.CONTACT.EMAIL}`}
                className={classes.textContactLink}
                underline="hover"
              >
                {appStrings.CONTACT.EMAIL}
              </Link>
            </div>
          </div>

          <Typography className={classes.openHoursTitle}>
            {appStrings.CONTACT.OPENING_TITLE}
          </Typography>
          <table className={classes.openHoursTable}>
            <tbody>
              <tr>
                <td>{appStrings.CONTACT.OPENING.MON_THU}:</td>
                <td className={classes.openHours}>
                  {appStrings.CONTACT.OPENING.MON_THU_HOURS}
                </td>
              </tr>
              <tr>
                <td>{appStrings.CONTACT.OPENING.FRIDAY}:</td>
                <td className={classes.openHours}>
                  {appStrings.CONTACT.OPENING.FRIDAY_HOURS}
                </td>
              </tr>
              <tr>
                <td>{appStrings.CONTACT.OPENING.SATURDAY}:</td>
                <td className={classes.openHours}>
                  {appStrings.CONTACT.OPENING.SATURDAY_HOURS}
                </td>
              </tr>
              <tr>
                <td>{appStrings.CONTACT.OPENING.SUNDAY}:</td>
                <td className={classes.openHours}>
                  {appStrings.CONTACT.OPENING.SUNDAY_HOURS}
                </td>
              </tr>
            </tbody>
          </table>

          <Button
            variant="contained"
            color="primary"
            component={RouterLink}
            to={routes.CUSTOMER_SERVICE}
            className={classes.contactButton}
          >
            {appStrings.MENU.CUSTOMER_SERVICE}
          </Button>
        </div>

        {/* section: address and links */}
        <div className={clsx(classes.section, classes.mainSection)}>
          <div className={classes.addressSection}>
            <Hidden lgDown>
              <Logo className={classes.logo} />
            </Hidden>
            <Typography variant="p2" className={classes.title}>
              {appStrings.MENU.PRODUCTS}
            </Typography>
            <Link
              component={RouterLink}
              to={routes.PRODUCTS_REFINANCING}
              className={classes.link}
              underline="hover"
            >
              {appStrings.MENU.PRODUCTS_REFINANCING}
            </Link>

            <div className={classes.termsLinksContainer}>
              <Link
                component={RouterLink}
                to={routes.TERMS_OF_USE_MIN_SCOOPR}
                className={classes.link}
                underline="hover"
              >
                {appStrings.MENU.TERMS_OF_USE}
              </Link>
              <Link
                component={RouterLink}
                to={routes.PRIVACY_STATEMENT}
                className={classes.link}
                underline="hover"
              >
                {appStrings.MENU.PRIVACY}
              </Link>
              <Link
                component={RouterLink}
                to={routes.COOKIES}
                className={classes.link}
                underline="hover"
              >
                {appStrings.MENU.COOKIES}
              </Link>
            </div>

            <Hidden lgUp>
              <Logo className={classes.logo} />
            </Hidden>
            <address>
              {[
                ...appStrings.CONTACT.COMPANY,
                ...appStrings.CONTACT.ADDRESS,
              ].map((text, i) => (
                <Typography
                  variant={[0, 2, 4].includes(i) ? 'p3ExtraBold' : 'p3'}
                  className={clsx(i % 2 === 0 && classes.textAddress)}
                  key={text}
                >
                  {text}
                </Typography>
              ))}
              <IconButton
                component={Link}
                className={classes.buttonFacebook}
                href={LINKS.FACEBOOK}
                rel="noopener noreferrer"
                aria-label={appStrings.CONTACT.FACEBOOK_LABEL}
                size="large"
              >
                <FacebookLogo />
              </IconButton>
            </address>
          </div>

          <div className={classes.linksSection}>
            <div className={classes.linksList}>
              <Typography variant="p2" className={classes.title}>
                {appStrings.MENU.MY_ECONOMY}
              </Typography>
              {economyLinksLeft.map(({ name }) => (
                <Link
                  key={name}
                  component={RouterLink}
                  to={routes[name]}
                  className={classes.link}
                  underline="hover"
                >
                  {appStrings.MENU[name]}
                </Link>
              ))}
            </div>
            <div className={classes.linksList}>
              {economyLinksRight.map(({ name }) => (
                <Link
                  key={name}
                  component={RouterLink}
                  to={routes[name]}
                  className={classes.link}
                  underline="hover"
                >
                  {appStrings.MENU[name]}
                </Link>
              ))}
            </div>
          </div>
        </div>
      </div>

      <div className={classes.fotterBottom}>
        <div className={classes.fotterBottomContent}>
          <div className={classes.footerLinks}>
            <Link
              component={RouterLink}
              to={routes.TERMS_OF_USE_MIN_SCOOPR}
              className={classes.footerBottomLink}
              underline="hover"
            >
              {appStrings.MENU.TERMS_OF_USE}
            </Link>
            <span className={classes.dot} />
            <Link
              component={RouterLink}
              to={routes.PRIVACY_STATEMENT}
              className={classes.footerBottomLink}
              underline="hover"
            >
              {appStrings.MENU.PRIVACY_STATEMENT}
            </Link>
            <span className={classes.dot} />
            <Link
              component={RouterLink}
              to={routes.COOKIES}
              className={classes.footerBottomLink}
              underline="hover"
            >
              {appStrings.MENU.COOKIES}
            </Link>
          </div>

          <Typography variant="p3">{appStrings.RIGHTS}</Typography>
        </div>
      </div>
    </footer>
  );
};

Footer.propTypes = {};

export default Footer;

import makeStyles from '@mui/styles/makeStyles';

export default makeStyles(({ breakpoints }) => ({
  logoRoot: {
    width: 'auto',
    height: 18,
    [breakpoints.up('md')]: {
      height: 25,
    },
  },
}));

import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';

import CircleIcon from '../../assets/icons/CircleIcon';
import { palette } from '../../assets/styles/theme';
import Typography from '../Typography';

import useStyles from './CircularLoader.styles';

const CIRCLES = [
  {
    key: 1,
    color: palette.primary.main,
    strokeWidth: 6,
    rotation: 'Right',
  },
  {
    key: 2,
    color: palette.accent.main,
    strokeWidth: 4.1,
    rotation: 'Left',
  },
  {
    key: 3,
    color: palette.secondary.main,
    strokeWidth: 3,
    rotation: 'Right',
  },
  {
    key: 4,
    color: palette.accent.main,
    strokeWidth: 2.4,
    rotation: 'Left',
  },
  {
    key: 5,
    color: palette.primary.main,
    strokeWidth: 2,
    rotation: 'Right',
  },
];

const CircularLoader = ({ text, title, description }) => {
  const classes = useStyles();

  return (
    <div className={classes.loaderContainer}>
      {title || description ? (
        <Box mt={5} className={classes.topBox}>
          <Typography variant="h3">{title}</Typography>
          <Typography component="p" className={classes.description}>
            {description}
          </Typography>
        </Box>
      ) : null}
      <div className={classes.circlesContainer}>
        <div className={classes.innerCircle} />
        {CIRCLES.map(circle => (
          <div
            key={circle.key}
            className={classes[`circleRotate${circle.rotation}`]}
          >
            <CircleIcon
              color={circle.color}
              strokeWidth={circle.strokeWidth}
              className={clsx(classes.circle, classes[`circle${circle.key}`])}
            />
          </div>
        ))}
      </div>

      {text ? (
        <Box mt={5}>
          <ReactMarkdown children={text} rehypePlugins={[rehypeRaw]} />
        </Box>
      ) : null}
    </div>
  );
};

CircularLoader.propTypes = {
  text: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
};

CircularLoader.defaultProps = {
  text: undefined,
  title: null,
  description: null,
};

export default CircularLoader;

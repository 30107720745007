import React from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { ListItem, ListItemText } from '@mui/material';

import useStyles from './NavItem.styles';

const NavItem = ({ menuItem, onClick, ...rest }) => {
  const classes = useStyles();
  const { title, href } = menuItem;

  return (
    <ListItemLink
      to={href}
      {...rest}
      onClick={() => {
        if (onClick) {
          onClick();
        }
      }}
    >
      <ListItemText className={classes.navItemText}>{title}</ListItemText>
    </ListItemLink>
  );
};

NavItem.propTypes = {
  menuItem: PropTypes.object.isRequired,
  onClick: PropTypes.any,
};
NavItem.defaultProps = {
  onClick: undefined,
};

export default NavItem;

const ListItemLink = ({ ...props }) => {
  const classes = useStyles();
  return (
    <ListItem
      button
      disableRipple
      disableGutters
      className={classes.menuItem}
      component={React.forwardRef((linkProps, ref) => (
        <RouterLink
          exact
          onClick={() => {
            console.log('ahaa!');
          }}
          ref={ref}
          activeClassName={classes.menuItemActive}
          {...linkProps}
        />
      ))}
      {...props}
    />
  );
};

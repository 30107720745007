import Http from '../Http';
import { API } from '../../Config';

function apiGetAccountMe() {
  return Http.get(`${API.url}/account/me/`);
}

function apiDeleteAccountDelete() {
  return Http.delete(`${API.url}/account/delete/`);
}

function apiGetConsent(perPage) {
  return Http.get(`${API.url}/bi/consent/?per_page=${perPage}`);
}

function apiGetAccountLogin(local) {
  return Http.get(
    `${API.url}/account/login/${
      local ? `?redirect_url=http://localhost:3000/min-scoopr/loading` : ''
    }`,
  );
}

function apiPatchAccountMe(email) {
  return Http.patch(`${API.url}/account/me/`, {
    email,
  });
}

function apiPatchConsentClustersApplyConsents(consents) {
  return Http.patch(`${API.url}/bi/consent-clusters/apply_consents/`, {
    slugs: [...consents],
  });
}

function apiPostAccountLogout(access, refresh) {
  return Http.post(`${API.url}/account/logout/`, {
    access,
    refresh,
  });
}

function apiPostAuthToken(sessionId, extSessionId) {
  const values = {};
  if (extSessionId) {
    values.session_brain_goal_ext_id = extSessionId;
  } else {
    values.brain_session_id = sessionId;
  }
  return Http.post(`${API.url}/authorization/session/token/`, values);
}

function apiPostAuthTokenRefresh(refresh) {
  return Http.post(`${API.url}/authorization/session/token/refresh/`, {
    refresh,
  });
}

function apiPostConsentClustersAllConsents(consents) {
  return Http.post(`${API.url}/bi/consent-clusters/all_consents_check/`, {
    slugs: [...consents],
  });
}

export const AuthHttp = {
  get: {
    accountMe: apiGetAccountMe,
    accountLogin: apiGetAccountLogin,
    consent: apiGetConsent,
  },
  post: {
    accountLogout: apiPostAccountLogout,
    authToken: apiPostAuthToken,
    authTokenRefresh: apiPostAuthTokenRefresh,
    consentsCheckAll: apiPostConsentClustersAllConsents,
  },
  patch: {
    accountMe: apiPatchAccountMe,
    consentClusterApplyConsents: apiPatchConsentClustersApplyConsents,
  },
  delete: {
    accountDelete: apiDeleteAccountDelete,
  },
};
